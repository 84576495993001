<template>
    <div id="main">
        <div id="part1" class="part">
            <div id="cont1">
                <div id="text1">
                    <h1>Więcej czasu.<br>Mniej bankowości.</h1>
                    <h1 class="bigger">Zero frustracji.</h1>
                </div>
                <div id="part2" class="part">
                    <router-link to="/rejestracja" class="button">ZAREJESTRUJ SIĘ</router-link>
                </div>
            </div>
            <div id="photo1"></div>
        </div>
        <div id="part3" class="part">
            <div id="photo3"></div>
            <div id="text3">
                <h1>Dokumenty i płatności są koniecznością…</h1>
                <h2>…ale nikt nie ma ochoty poświęcać <br>na nie czasu. Masz przecież wiele<br> innych, równie ważnych rzeczy <br>na głowie.</h2>
                <h2 id="part3-wide">Jak w takim razie ograniczyć do minimum czas poświęcany na wypełnianie faktur i wykonywanie płatności?</h2>
            </div>
        </div>
        <div id="part4" class="part">
            <div id="dismiss1">
                <h3>Możesz dalej wszystko robić sam?</h3>
                <a>Dokładnie tak, jak robiłeś do tej pory. Bardzo łatwo jednak coś przegapić, do tego zajmuje to naprawdę dużo czasu. <br><br>Zdecydowanie nie jest to optymalne rozwiązanie.</a>
            </div>
            <h2>ALBO</h2>
            <div id="dissmiss2">
                <h3>Możesz zatrudnić asystenta?</h3>
                <a>Znalezienie dobrych ludzi nie jest proste. Szkolenie i wdrażanie pracowników jest kosztowne. <br><br>Szkoda poświęcać wypłatę wysokości średniej krajowej na pomoc w wykonaniu płatności.</a>
            </div>
        </div>
        <div id="part5" class="part">
            <h2>A gdyby dało się połączyć wydajność nowego pracownika z kosztami zajęcia się tym samemu?<br><br>Pay24Hrs</h2>
            <div id="guarantees">
                <div id="guarantee1" class="guarantee">
                    <h3>Oszczędność <br>czasu</h3>
                    <a>Twój czas jest bardzo cenny – nie ma sensu tracić go na powtarzalne czynności związane z płatnościami. <br><br>Pomożemy Ci ograniczyć je do minimum.</a>
                </div>
                <div id="guarantee2" class="guarantee">
                    <h3>Mniej <br>bankowości</h3>
                    <a>Od teraz nie przegapisz ani jednej płatności, nie zgubisz danych żadnego klienta i będziesz miał bezproblemowy dostęp do wszystkich danych użytych w przeszłości. <br><br>Wszystko w jednym miejscu</a>
                </div>
                <div id="guarantee3" class="guarantee">
                    <h3>Zero <br>frustracji</h3>
                    <a>Możesz pożegnać się z ręcznym przepisywaniem danych klientów, klikaniem dziesiątek przelewów i walką z nieczytelnym interfejsem.<br><br>Najbardziej intuicyjny system, z jakiego kiedykolwiek korzystałeś. </a>
                </div>
                <div id="guarantee4" class="guarantee">
                    <h3>Korzystne <br>rozwiązanie</h3>
                    <a>Płacisz kilkadziesiąt razy mniej niż potencjalnemu pracownikowi, poświęcasz kilkadziesiąt razy mniej czasu niż gdybyś robił to ręcznie.<br><br>Najbardziej rozsądny stosunek poświęconego czasu do ceny usługi. </a>
                </div>
            </div>
        </div>
        <div id="part8">
            <h1>Dołącz do Newslettera, aby dowiedzieć się więcej</h1>
            <h4>Odkryj, jak możesz zaoszczędzić więcej czasu i zarobić więcej. Poznaj nowe funkcje Pay24Hrs</h4>
            <form ref="aweberForm" method="post" accept-charset="UTF-8" action="https://www.aweber.com/scripts/addlead.pl" class="form-class">
                <div style="display: none;">
                    <input type="hidden" name="meta_web_form_id" value="993951658" />
                    <input type="hidden" name="meta_split_id" value="" />
                    <input type="hidden" name="listname" value="awlist6728358" />
                    <input type="hidden" name="redirect" value="https://pay24hrs.com/thanks" id="redirect_5494f0ddca3a92a0b39c8f1a12167359" />
                    <input type="hidden" name="meta_redirect_onlist" value="https://pay24hrs.com/subscribed" />
                    <input type="hidden" name="meta_adtracking" value="My_Web_Form" />
                    <input type="hidden" name="meta_message" value="1" />
                    <input type="hidden" name="meta_required" value="name,email" />

                    <input type="hidden" name="meta_tooltip" value="" />
                </div>

                <div class="field-container">
                    <input id="nameNewsletter" type="text" name="name" :placeholder="nameNewsletterPlaceholder" class="mail" v-model="nameNewsletter">
                    <input id="emailNewsletter" type="email" name="email" :placeholder="emailNewsletterPlaceholder" class="mail" v-model="emailNewsletter">
                    <input type="submit" class="submit sendNews" value="SUBSKRYBUJ ⟶">
                    <label for="checkbox"><input type="checkbox" required id="checkbox">Akceptuję politykę cookies i politykę prywatności</label>
                </div>
            </form>
        </div>
        <div id="part6" class="part">
            <div id="cont6">
                <div id="text6">
                    <h2>Zarejestruj się i zobacz za darmo, z czego możesz już skorzystać. <br><br>Możesz spodziewać się między innymi bardzo prostej i bardzo szybkiej metody wystawiania faktur.</h2>
                </div>
                <div id="part7" class="part">
                    <router-link to="/rejestracja" class="button">ZAREJESTRUJ SIĘ</router-link>
                </div>
            </div>
            <div id="photo6"></div>
        </div>

    </div>
</template>
<script>
import { ref, reactive, onMounted, inject } from 'vue';
import axios from 'axios';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export default {
    setup() {
        document.title = "Pay24Hrs";
        const endpoint = inject("g_endpoint");

        const nameNewsletter = ref('');
        const emailNewsletter = ref('');
        const nameNewsletterPlaceholder = ref('Imię');
        const emailNewsletterPlaceholder = ref('Email');
        const warnings = reactive({ emailWarning1: '' });

        const aweberForm = ref(null);

        const sendNewsletter = async () => {
            if (!emailRegex.test(emailNewsletter.value)) {
                warnings.emailWarning1 = "Niepoprawny adres e-mail";
                emailNewsletterPlaceholder.value = warnings.emailWarning1;
                return;
            }

            try {
                await axios.post(`${endpoint}add_newsletter_email/`, JSON.stringify({ email: emailNewsletter.value }), {
                    withCredentials: true,
                    headers: { 'Content-Type': "application/json" }
                });
                warnings.emailWarning1 = "Dziękujemy za zapisanie się!";
            } catch (error) {
                console.error("Błąd podczas wysyłania danych:", error);
                warnings.emailWarning1 = "Wystąpił błąd podczas zapisywania się";
            } finally {
                emailNewsletterPlaceholder.value = warnings.emailWarning1;
            }
        };

        const handleFormSubmit = async (event) => {
            event.preventDefault();
            await sendNewsletter();
            aweberForm.value.submit();
        };

        onMounted(() => {
            if (aweberForm.value) {
                aweberForm.value.addEventListener('submit', handleFormSubmit);
            }
        });

        return { nameNewsletter, emailNewsletter, nameNewsletterPlaceholder, emailNewsletterPlaceholder, warnings, sendNewsletter, aweberForm };
    }
}
</script>


<style lang="scss" scoped>
@import '../styles/style.scss';
    #main
    {
        width: 100%;
        display: flex;
        flex-direction: column;

        .part
        {
            width: 100%;
            padding-top: 2%;
            padding-bottom: 2%;
        }

        #part1
        {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 2rem;

            #cont1
            {
                width: 100%;
            }

            h1
            {
                font-size: 2.5rem;
                font-weight: 300;
                margin: 0;
                width: 100%;
            }

            .bigger
            {
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
                font-size: 4rem;
                line-height: 5rem;
            }

            #text1
            {
                width: 100%;
            }

            #photo1
            {
                width: 60%;
                max-width: 700px;

                border-radius: 25px;

                background-image: url('../assetsPng/photo1.png');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: right;

                display: none;
            }
        }

        #part2
        {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            a
            {
                padding-left: 4%;
                padding-right: 4%;

                padding-top: 8%;
                padding-bottom: 8%;

                margin: 0;
            }
        }

        #part3
        {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            h1
            {
                text-align: left;
                font-size: 2.5rem;
                font-weight: 300;
                margin: 0;
                margin-top: 1.5rem;
                width: 100%;
            }

            h2
            {
                text-align: left;
                width: 100%;
                font-size: 1.4rem;
                font-weight: 200;
                margin-top: 2rem;
                margin-bottom: 2rem;
            }

            #part3-wide
            {
                font-size: 2.5rem;
                width: 100%;
                font-weight: bolder;
                margin: 0;
            }

            #text3
            {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }

            #photo3
            {
                width: 50%;
                max-width: 700px;

                border-radius: 25px;

                background-image: url('../assetsPng/photo3.png');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: left bottom;

                display: none;
            }
        }

        #part4
        {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;
            padding-bottom: 5%;
            padding-top: 5%;

            h2
            {
                font-size: 5rem;
                margin: auto;
            }

            div
            {
                width: 100%;
                background-color: white;
                border-radius: 25px;
                box-shadow: 0 0 0.8vh 0.01vh $border-color2;
                text-align: left;
                padding: 4%;
                margin-top: 2rem;
                margin-bottom: 2rem;

                h3
                {
                    text-align: center;
                    font-size: 1.5rem;
                    line-height: 2rem;
                    margin: 0;
                    margin-bottom: 2rem;
                }

                a
                {
                    line-height: 2rem;
                    font-size: 1.2rem;
                }
            }
        }

        #part5
        {
            width: 100vw;
            padding-left: 12%;
            padding-right: 12%;
            background-image: url('../assets/banner-korzysci.svg');
            background-size: 700%;
            background-repeat: no-repeat;
            background-position: left top;
            transform: translateX(-10vw);
            padding-bottom: 0;

            h2
            {
                border-left: solid 3px white;
                padding-left: 3%;
                color: white;
                font-size: 2rem;
                width: 100%;
            }

            #guarantees
            {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .guarantee
                {
                    width: 100%;
                    background-color: white;
                    border-radius: 25px;
                    box-shadow: 0 0 0.8vh 0.01vh $border-color2;
                    text-align: center;
                    padding: 2%;
                    margin-bottom: 2rem;
                    margin-top: 2rem;

                    h3
                    {
                        text-align: center;
                        font-size: 1.5rem;
                        line-height: 2rem;
                        margin: 0;
                        margin-bottom: 2rem;
                    }

                    a
                    {
                        line-height: 2rem;
                        font-size: 1.1rem;
                    }
                }
            }
        }

        #part6
        {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0;
            margin-bottom: 2rem;

            #cont6
            {
                width: 100%;
            }

            h2
            {
                font-size: 2rem;
                width: 100%;

                margin: 0;
                margin-bottom: 2rem;
            }

            #text6
            {
                width: 100%;
            }

            #photo6
            {
                width: 60%;
                max-width: 700px;

                border-radius: 25px;

                background-image: url('../assetsPng/photo2.png');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: right;

                display: none;
            }
        }

        #part7
        {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            a
            {
                padding-left: 4%;
                padding-right: 4%;

                padding-top: 8%;
                padding-bottom: 8%;

                margin: 0;
            }
        }

        #part8
        {
            background-image: url('../assets/banner-newsletter.svg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            width: 100%;
            margin-left: auto;
            margin-right: auto;

            padding: 5%;
            text-align: center;
            color: white;
            margin-bottom: 2rem;
            margin-top: 1rem;
            border-radius: 20px;

            & h4
            {
                transform: translateY(-1rem);
            }

            h1
            {
                margin-top: 0;
            }

            label
            {
                width: 47.5%;
                display: flex;
                justify-content: space-between;
            }
        }

        form
        {
            width: 100%;
            display: flex;
            flex-direction: column;
            .field-container
            {
                display: flex;
                flex-direction: column;
                align-items: center;

                input
                {
                    margin: 0;
                    margin-bottom: 5%;
                }
            }
            & .mail
            {
                width: 100%;
                padding: 1%;
                padding-left: 5%;
                border: none;
                outline: none;
                background-color: $grey-color;
                line-height: 2rem;
                margin-right: 5%;
                border-radius: 10px;
                margin-bottom: 2rem;
            }
            & .submit
            {
                width: 100%;
                padding: 1%;
                font-size: 1.2rem;
                line-height: 2rem;

                cursor: pointer;

                display: flex;
                justify-content: center;
                align-items: center;

                transition: 0.2s ease-in-out;
                border: 1px solid white;
                border-radius: 15px;
                text-decoration: none;
                color: $main-color;
                background-color: white;
                font-weight: 200;
                cursor: pointer;

                &:hover
                {
                    background-color: $main-color;
                    border: 1px solid $main-color;
                    color: white;
                }
            }
        }

        .button
        {
            height: 3rem;
            font-size: 1.6rem;
            padding: 3%;

            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 2%;

            transition: 0.2s ease-in-out;
            border: 1px solid $main-color;
            border-radius: 25px;
            text-decoration: none;
            color: white;
            background-color: $main-color;
            font-weight: 200;
            cursor: pointer;

            &:hover
            {
                background-color: white;
                color: $main-color;
            }
        }
    }

    @media only screen and (min-width: 1000px)
    {
        #main
        {
            #part1
            {
                #cont1
                {
                    width: 40%;
                }

                h1
                {
                    font-size: 3rem;
                }

                .bigger
                {
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                    font-size: 6rem;
                    line-height: 6rem;
                }

                #photo1
                {
                    display: inline;
                }
            }

            #part2
            {
                justify-content: flex-start;
            }

            #part3
            {
                h1
                {
                    font-size: 3rem;
                    margin: 0;
                }

                h2
                {
                    font-size: 1.5rem;
                }

                #part3-wide
                {
                    font-size: 1.5rem;
                }

                #text3
                {
                    width: 40%;
                }

                #photo3
                {
                    display: inline;
                }
            }

            #part4
            {
                flex-direction: row;

                div
                {
                    width: 30%;
                    margin: 0;
                }
            }

            #part5
            {
                background-size: 160%;
                background-position: right top;

                h2
                {
                    width: 50%;
                }

                #guarantees
                {
                    flex-direction: row;
                    .guarantee
                    {
                        width: 22%;
                        margin-bottom: 5%;
                    }
                }
            }

            #part6
            {
                #cont6
                {
                    width: 40%;
                }

                #photo6
                {
                    display: inline;
                }
            }

            #part7
            {
                justify-content: flex-start;
            }

            #part8
            {
                width: 80%;

                margin-bottom: 4rem;
                margin-top: 0;
            }

            form
            {
                display: inline;

                .field-container
                {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    input
                    {
                        margin: 0;
                        margin-bottom: 2%;
                    }
                }
                & .mail
                {
                    width: 47.5%;
                    margin-bottom: 0;
                }
                & .submit
                {
                    width: 47.5%;
                }
            }

            .button
            {
                font-size: 2.5rem;
            }
        }
    }

    @media screen and (min-width: 1875px)
    {
        #part5
        {
            transform: translateX(calc(750px - 50vw)) !important;
            background-size: 100% !important;
        }
    }
</style>