<template>
    <div id="view">
        <div id="view-search">
            <form>
                <input type="text" id="view-search-type" placeholder="Wyszukaj" v-model="search"/>
                <input type="button" id="view-search-button" value="Szukaj"/>
            </form>
        </div>
        <a id="errorCode"></a>
        <div id="view-archive">
            <div id="view-archive-top">
                <a class="make-space"><input type="checkbox" id="all" class="check"/></a>
                <a class="fakturaName">Nazwa</a>
                <a class="make-space">Zapisz</a>
                <a id="edit-all" class="make-space">Edytuj</a>
                <a id="remove-all" class="make-space">Usuń</a>
            </div>
            <div id="view-archive-bottom">
                <div class="faktura" v-for="faktura in fakturyList" :key="faktura">
                    <a class="make-space"><input type="checkbox" :id="`checkbox${faktura.fakturaId}`" class="check check1"/></a>
                    <a class="fakturaName" @click="editElement(faktura.fakturaId)">{{ faktura.fakturaTitle }}</a>
                    <a class="make-space" :id="`Pobierz${faktura.fakturaId}`">PDF</a>
                    <a class="make-space" :id="`Edytuj${faktura.fakturaId}`">Edytuj</a>
                    <a class="make-space" :id="`Usun${faktura.fakturaId}`">Usuń</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { onMounted, ref, inject } from 'vue';
import VueCookies from 'vue-cookies'
import axios from "axios";
import router from '@/router';

export default {

    setup()
    {
        document.title = 'Archiwum Faktur';
        const endpoint = inject("g_endpoint")
        var fakturyList = ref([]); //lista faktur
        var chosenList = ref([]); //lista zaznaczonych checkboxów
        var search = ref("");//wyszukiwany tekst

        //wczytanie faktur z api
        const fetchData = async () =>
        {
            try
            {
                const response = await axios.get(`${endpoint}factures/?user=${VueCookies.get("UserId")}`, {
                withCredentials: true,
                headers: {
                    'X-CSRFToken': `${VueCookies.get('TokenCSRF')}`,
                    'Authorization': `Token ${VueCookies.get('TokenDRF')}`
                }});
                response.data.forEach(element =>
                {
                    fakturyList.value.push({
                        fakturaId: element.id,
                        fakturaTitle: element.number
                    })
                });
                fakturyList.value = fakturyList.value.reverse()
                document.getElementById("errorCode").innerText = ""
                setTimeout(addListeners, 0);
            }
            catch(error)
            {
                document.getElementById("errorCode").innerText = "Błąd wczytywania danych. Odśwież stronę"
                console.error(error);
            }
        }

        //edytowanie faktury
        const editElement = async (id) =>
        {
            router.push({name: 'import', params: {id: String(id)}})
        }

        //edytowanie zaznaczonych faktur
        const editMultipleElements = async () =>
        {
            router.push({name: 'import', params: {id: String(chosenList.value)}})
        }

        //usunięcie faktury
        const removeElement = async (id) =>
        {
            try
            {
                await axios
                .delete(`${endpoint}factures/${id}`,
                {
                    withCredentials: true,
                    headers:
                    {
                        'X-CSRFToken': `${VueCookies.get('TokenCSRF')}`,
                        'Authorization': `Token ${VueCookies.get('TokenDRF')}`
                    }
                });
            }
            catch(error)
            {
                console.error(error)
            }
            fakturyList.value = [];

            fetchData()
        }

        //usunięcie zaznaczonych faktur
        const removeMultipleElements = async () =>
        {
            chosenList.value.forEach(async (id) => {
                try
                {
                    await axios
                    .delete(`${endpoint}factures/${id}`,
                    {
                        withCredentials: true,
                        headers:
                        {
                            'X-CSRFToken': `${VueCookies.get('TokenCSRF')}`,
                            'Authorization': `Token ${VueCookies.get('TokenDRF')}`
                        }
                    });
                }
                catch(error)
                {
                    console.error(error)
                }
                fakturyList.value = [];

                fetchData()
            })

        }

        const downloadElement = async (id) =>
        {
            let wasGenerated = false //on sprawdza, czy była już wygenerowana
            let generatedId = id //on szuka id tego co zostało już wygenerowane, i to właśnie sprawia mi problem

            try
            {
                const response = await axios
                .get(`${endpoint}generated_factures/?facture=${id}`,//2
                {
                    withCredentials: true,
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': `${VueCookies.get('TokenCSRF')}`,
                        'Authorization': `Token ${VueCookies.get('TokenDRF')}`
                    }
                });
                if(response.data[0])
                {
                    wasGenerated = true
                    generatedId = response.data[0].id
                }
            }
            catch(error)
            {
                console.error(error)
                wasGenerated = false
            }

            try
            {
                let xD =
                {
                    xD: "xD"
                }
                let send = JSON.stringify(xD)


                if(!wasGenerated)
                {
                    const response = await axios
                    .post(`${endpoint}factures/${generatedId}/generate/`, send,
                    {
                        withCredentials: true, headers:
                        {
                            'Content-Type': 'application/json',
                            'X-CSRFToken': `${VueCookies.get('TokenCSRF')}`,
                            'Authorization': `Token ${VueCookies.get('TokenDRF')}`
                        }
                    });
                    generatedId = response.data.generated_facture_id
                    console.log(response)
                }

                const downloadResponse = await axios
                .get(`${endpoint}generated_factures/${generatedId}/download/`,//2
                {
                    withCredentials: true,
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': `${VueCookies.get('TokenCSRF')}`,
                        'Authorization': `Token ${VueCookies.get('TokenDRF')}`
                    }
                });
                console.log(downloadResponse)
                window.open(`${endpoint}generated_factures/${generatedId}/download/`, `_blank`)
            }
            catch(error)
            {
                console.error(error)
            }

        }

        //wyszukiwanie faktur
        const findElements = (find) =>
        {
            const tabelaAbc = fakturyList.value
            .map(element => ({ fakturaId: element.fakturaId, fakturaTitle: element.fakturaTitle }))
            .filter(element => element.fakturaTitle.toLowerCase().includes(find));

            chosenList.value = [];
            fakturyList.value.forEach(element =>
            {
                document.getElementById(`checkbox${element.fakturaId}`).checked = false;
            })
            tabelaAbc.forEach(element =>
            {
                chosenList.value.push(element.fakturaId);
                document.getElementById(`checkbox${element.fakturaId}`).checked = true;
            })
        }

        //dodanie listenerów
        const addListeners = () =>
        {
            document.getElementById("view-search-button").addEventListener("click", () =>
            {
                const inputValue = document.getElementById("view-search-type").value.toLowerCase();
                findElements(inputValue);
            });

            document.getElementById("all").addEventListener("change", () =>
            {
                let czy = document.getElementById("all").checked;
                [...document.getElementsByClassName("check1")].forEach(element =>
                {
                    let numberString = element.id.substring(8);
                    let number = parseInt(numberString, 10);
                    if(czy == false)
                    {
                        chosenList.value = [];
                        element.checked = false;

                    }
                    else
                    {
                        if(chosenList.value.indexOf(number) == -1)
                        {
                            chosenList.value.push(number);
                        }
                        element.checked = true;
                    }
                });
            })

            document.getElementById("edit-all").addEventListener("click", editMultipleElements)
            document.getElementById("remove-all").addEventListener("click", removeMultipleElements)

            fakturyList.value.forEach(element => {
                document.getElementById(`checkbox${element.fakturaId}`).addEventListener("change", () =>
                {
                    chosenList.value.push(element.fakturaId);
                    let box = document.getElementById(`checkbox${element.fakturaId}`);
                    let index = chosenList.value.indexOf(element.fakturaId);
                    if(!box.checked)
                    {
                        while (index != -1)
                        {
                            chosenList.value.splice(index, 1);
                            index = chosenList.value.indexOf(element.fakturaId);
                        }
                    }
                });

            });

            fakturyList.value.forEach((element) =>
            {
                if(document.getElementById(`Usun${element.fakturaId}`))
                {
                    document.getElementById(`Usun${element.fakturaId}`).addEventListener("click", () => {removeElement(element.fakturaId)})
                }

                if(document.getElementById(`Edytuj${element.fakturaId}`))
                {
                    document.getElementById(`Edytuj${element.fakturaId}`).addEventListener("click", () => {editElement(element.fakturaId)})
                }

                if(document.getElementById(`Pobierz${element.fakturaId}`))
                {
                    document.getElementById(`Pobierz${element.fakturaId}`).addEventListener("click", () => {downloadElement(element.fakturaId)})
                }
            });
        }

        onMounted(() =>
        {
            fetchData();
        })

        return {fakturyList, search, editElement}
    }
}
</script>
<style lang="scss" scoped>
@import 'src/styles/style.scss';
    #view
    {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        & .fakturaName
        {
            word-break: break-all;
            cursor: pointer;
        }
        & .check
        {
            transform: scale(2);
        }
        &-search
        {
            width: 70%;
            margin-top: 5%;
            margin-bottom: 5%;
            &-type
            {
                width: 47.5%;
                padding: 1%;
                padding-left: 5%;
                outline: none;
                background-color: $grey-color;
                line-height: 2rem;
                margin-right: 5%;
                border-radius: 25px;
            }
            &-button
            {
                width: 47.5%;
                padding: 1%;
                outline: none;
                background-color: $main-color;
                color: white;
                line-height: 2rem;
                font-weight: bold;
                border-radius: 25px;

                display: flex;
                justify-content: center;
                align-items: center;

                transition: 0.2s ease-in-out;
                border: 1px solid $main-color !important;
                text-decoration: none;
                cursor: pointer;

                &:hover
                {
                    background-color: white;
                    color: $main-color;
                }
            }
            & form
            {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
            }
        }
        &-archive
        {
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 5%;
            &-top
            {
                background-color: $main-color;
                color: white;
                min-height: 3rem;
                margin-top: 2%;
                border-radius: 1vh;
                padding-top: 2%;
                padding-bottom: 2%;
                padding-left: 0 !important;
                padding-right: 0 !important;
                width: 100%;
                display: flex;
                justify-content: space-between;
                & .make-space
                {
                    width: 100%;
                    padding-right: 1%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 2;
                    cursor: pointer;
                }
                & .fakturaName
                {
                    width: 100%;
                    display: flex;
                    align-items: center;
                }
            }
            &-bottom
            {
                width: 100%;
                & .faktura
                {
                    min-height: 3rem;
                    margin-top: 5%;
                    background-color: $grey-color;
                    border-radius: 1vh;
                    padding-top: 2%;
                    padding-bottom: 2%;
                    width: 100%;
                    color: $font-color;
                    display: flex;
                    justify-content: space-between;
                    & .make-space
                    {
                        width: 100%;
                        padding-right: 1%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-shrink: 2;
                        cursor: pointer;
                        transition: 0.2s ease-in-out;
                        &:hover
                        {
                            color: $main-color;
                        }
                    }
                    & .fakturaName
                    {
                        width: 100%;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }

        &-file
        {
            display: flex;
            flex-direction: column;
            width: 30%;
            align-items: center;
            justify-content: space-evenly;
            margin-top: 5%;
            & form
            {
                height: 100px;
            }
            & a
            {
                margin-top: 5%;
            }
            & form, a
            {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: space-between;
                white-space: nowrap;
                margin-right: 5%;
                & input {
                display: none;
                }
                & label
                {
                    width: 100%;
                    text-align: center;
                }
                & img
                {
                    width: auto;
                    height: 100%;
                    cursor: pointer;
                    border-radius: 100vw;
                }
            }
        }
    }
    @media only screen and (min-width: 1000px)
    {
        #view
        {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            & #fakturaName
            {
                word-break: break-all;
                padding-left: 2%;
                padding-right: 2%;
            }
            & .check
            {
                transform: scale(2);
            }
            &-search
            {
                width: 50%;
                margin-top: 5%;
                margin-bottom: 5%;
                &-type
                {
                    width: 47.5%;
                    padding: 1%;
                    padding-left: 5%;
                    border: none;
                    outline: none;
                    background-color: $grey-color;
                    line-height: 2rem;
                    margin-right: 5%;
                    border-radius: 1vh;
                }
                &-button
                {
                    width: 47.5%;
                    padding: 1%;
                    border: none;
                    outline: none;
                    background-color: $main-color;
                    color: white;
                    line-height: 2rem;
                    font-weight: bold;
                    border-radius: 1vh;
                }
                & form
                {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                }
            }
            &-archive
            {
                width: 80%;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin-bottom: 5%;
                &-top
                {
                    background-color: $main-color;
                    padding: 2%;
                    padding-left: 5%;
                    padding-right: 5%;
                    width: 100%;
                    color: white;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 2%;
                }
                &-bottom
                {
                    width: 100%;
                    & .faktura
                    {
                        margin-top: 2%;
                        background-color: $grey-color;
                        padding: 2%;
                        padding-left: 0%;
                        padding-right: 0%;
                        width: 100%;
                        color: $font-color;
                        display: flex;
                        justify-content: space-between;
                        & .make-space
                        {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }

            &-file
            {
                & form, a
                {
                    margin-right: 0;
                }
            }
        }
    }
</style>