<template>
    <div id="high">
        Tutaj kiedyś zostanie dodana opcja płatności zbliżeniowych - work in progress! 
    </div>
</template>
<script>
export default {
    setup()
    {
        document.title = 'Płatności Zbliżeniowe';
    }
}
</script>
<style lang="scss" scoped>
@import 'src/styles/style.scss';
    #high
    {
        min-height: 10vh;
        padding: 2%;
    }
</style>