<template>
    <div id="view">
        <h2>Kontakt</h2>
        <p>Ciekawi Cię Pay24hrs lub masz inne pytanie? Wypełnij formularz, a my skontaktujemy się z Tobą.<br>
        W przypadku wszelkich zapytań medialnych, skontaktuj się z nami pod adresem pay24hrs.contact@gmail.com</p>
        <p>Email: pay24hrs.contact@gmail.com</p>
    </div>
</template>
<script>
export default {
setup()
{
    document.title = 'Kontakt z administracją i pomocą techniczną Pay24Hrs';
}
}
</script>
<style lang="scss" scoped>
    #view
    {
        min-height: 80vh;
        p, h2
        {
            line-height: 1.4rem;
        }
    }
</style>