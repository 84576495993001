<template>
    <div id="main">
        <div id="part1" class="part">
            <div id="cont1">
                <div id="text1">
                    <h1>Dzięki za subskrybowanie naszego Newslettera!</h1>
                </div>
                <div id="part2" class="part">
                    <router-link to="/" class="button">POWRÓT</router-link>
                </div>
            </div>
            <div id="photo1"></div>
        </div>
    </div>
</template>
<script>
export default {
    setup()
    {
        document.title = 'Dziękujemy';
    }
}
</script>
<style lang="scss" scoped>
@import '../styles/style.scss';
    #main
    {
        min-height: 100vh;
        width: 100%;

        .part
        {
            width: 100%;
            padding-top: 2%;
            padding-bottom: 2%;
        }

        #part1
        {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 2rem;

            #cont1
            {
                width: 100%;
            }

            h1
            {
                font-size: 2.5rem;
                font-weight: 300;
                margin: 0;
                width: 100%;
            }

            .bigger
            {
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
                font-size: 4rem;
                line-height: 5rem;
            }

            #text1
            {
                width: 100%;
            }
        }

        #part2
        {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            a
            {
                padding-left: 4%;
                padding-right: 4%;

                padding-top: 8%;
                padding-bottom: 8%;

                margin: 0;
            }
        }

        .button
        {
            height: 3rem !important;
            font-size: 1.6rem;
            padding: 3% !important;

            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 2%;

            transition: 0.2s ease-in-out;
            border: 1px solid $main-color;
            border-radius: 25px;
            text-decoration: none;
            color: white;
            background-color: $main-color;
            font-weight: 200;
            cursor: pointer;

            &:hover
            {
                background-color: white;
                color: $main-color;
            }
        }
    }
    @media only screen and (min-width: 1000px)
    {
        #main
        {
            #part1
            {
                #cont1
                {
                    width: 100%;
                }

                h1
                {
                    font-size: 3rem;
                }
            }

            #part2
            {
                justify-content: flex-start;
            }
        }
    }
</style>