<template>
    <div id="main">
        <a id="top">
            <h1>{{ name }}</h1>
            <h4>{{ date }}</h4>
        </a>
        <div id="left-small">
            <div>
                <a v-show="!isWrapped" id="rozwin"><img src="../assets/zamknij-profil.svg"/>Rozwiń menu</a>
            </div>
        </div>
        <div id="left" v-show="isWrapped">
            <div id="left-top">
                <a id="logout"><div id="cofnij"></div> Wyloguj</a>
                <router-link to="/konto/zaplecze" id="left-top-zaplecze">Ustawienia konta</router-link>
                <a id="left-top-select">Przełącz rachunek <img src="../assets/arrow-down.svg"/></a>
                <a id="form"><form><div></div><input type="text" placeholder="Wyszukaj"/></form></a>
            </div>
            <div id="left-center">
                <router-link to="/konto"><div id="panel-icon" :class="[{'active-icon': $route.path === '/konto'}]"></div>Panel Użytkownika</router-link>
                <router-link to="/konto/faktury"><div id="archive-icon" :class="[{'active-icon': $route.path === '/konto/faktury'}]"></div>Archiwum Faktur</router-link>
                <router-link to="/konto/import"><div id="editor-icon" :class="[{'active-icon': $route.path === '/konto/import'}]"></div>Edytor Faktur</router-link>
                <router-link to="/konto/payments"><div id="payments-icon" :class="[{'active-icon': $route.path === '/konto/payments'}]"></div>Płatności Zbliżeniowe</router-link>
                <router-link to="/konto/ecommerce"><div id="platform-icon" :class="[{'active-icon': $route.path === '/konto/ecommerce'}]"></div>Platforma E-Commerce</router-link>
                <router-link to="/konto/rachunek"><div id="rachunek-icon" :class="[{'active-icon': $route.path === '/konto/rachunek'}]"></div>Dodatkowy Rachunek</router-link>
                <router-link to="/konto/sendInfo"><div id="help-icon" :class="[{'active-icon': $route.path === '/konto/sendInfo'}]"></div>Pomoc Techniczna</router-link>
                <router-link to="/konto/notification"><div id="center-icon" :class="[{'active-icon': $route.path === '/konto/notification'}]"></div>Centrum Powiadomień</router-link>
            </div>
            <div id="left-bottom">
                <a id="zwin"><img src="../assets/zamknij-profil.svg"/>Zwiń menu</a>
            </div>
        </div>
        <div id="center">
            <RouterView></RouterView>
        </div>
    </div>
</template>
<script>
import { onMounted, ref, inject } from 'vue';
import axios from 'axios';
import VueCookies from "vue-cookies"
import router from '@/router';

export default {
    setup()
    {
        const endpoint = inject("g_endpoint")
        var isWrapped = ref(1) //czy zwinięty na telefonie
        var name = ref("") //nazwa użytkownika

        var date = ref(0) //dzisiejsza data
        date = ref(new Date())
        const year = date.value.getFullYear()
        const month = String(date.value.getMonth() + 1).padStart(2, '0')
        const day = String(date.value.getDate()).padStart(2, '0')
        date.value = `${year}-${month}-${day}`

        //wylogowanie i usuwanie cookies
        const logout = async () =>
        {
            try
            {
                await axios.get(`${endpoint}logout/`)
                VueCookies.remove("tokenCSRF")
                VueCookies.remove("tokenDRF")
                VueCookies.remove("userId")
                const cookies = document.cookie.split(";")
                cookies.forEach(cookie =>
                {
                    const eqPos = cookie.indexOf("=");
                    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`
                });
                await router.push("/");
            }
            catch(error)
            {
                console.error(error)
            }
        }

        //wczytanie nazwy użytkownika
        const fetchData = async () =>
        {
            try
            {
                const response = await axios.get(`${endpoint}organizations/?user=${VueCookies.get("UserId")}`, {
                withCredentials: true,
                headers: {
                    'X-CSRFToken': `${VueCookies.get('TokenCSRF')}`,
                    'Authorization': `Token ${VueCookies.get('TokenDRF')}`
                }})
                name.value = `${response.data[0].name} ${response.data[0].email}`
            }
            catch(error)
            {
                console.error(error);
            }
        }

        onMounted(() =>
        {
            //jeśli nie jest zalogowany, to przenieś na główną stronę
            if(!VueCookies.get("TokenCSRF"))
                router.push("/")

            //rozwijanie i zwijanie menu
            var zwin = document.getElementById('zwin')
            var rozwin = document.getElementById('rozwin')
            zwin.addEventListener("click", () =>
            {
                isWrapped.value = 0;
            })
            rozwin.addEventListener("click", () =>
            {
                isWrapped.value = 1;
            })

            document.getElementById("logout").addEventListener("click", logout)
            fetchData()
        });
        return {isWrapped, name, date}
    }
}
</script>
<style lang="scss" scoped>
@import '../styles/style.scss';
    #main
    {
        width: 100vw;
        height: auto;
        margin-left: -10vw;
        padding: 1%;
        padding-top: 5%;
        display: grid;
        grid-template-areas:
        "top"
        "left"
        "center";
        grid-template-columns: 100%;
        grid-template-rows: auto;
        background-color: $grey-color;
    }
    #top
    {
        grid-area: top;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        margin-bottom: 5%;
        border-top-right-radius: 1vh;
        border-top-left-radius: 1vh;
        padding-left: 5%;
        padding-right: 5%;
        text-decoration: none;
        color: $font-color;
    }
    #left, #left-small
    {
        grid-area: left;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-right: 1vw;
        &-top
        {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            & a
            {
                width: 47.5%;
                display: flex;
                align-items: center;

                background-color: white;
                border-radius: 1vh;
                margin-bottom: 5%;
                cursor: pointer;


                & #cofnij
                {
                    width: 2rem; /* przykładowa szerokość */
                    height: 2rem; /* przykładowa wysokość */
                    background-size: cover;
                    margin: 0.5rem;
                    cursor: pointer;
                    background-image: url('../assets/cofnij.svg');
                }
                & img
                {
                    padding: 5%;
                }
                &:hover
                {
                    #cofnij{background-image: url('../assets/cofnij-2.svg');}
                }

            }
            & #form
            {
                width: 100%;
                & form
                {
                    width: 100%;
                    //padding: 2%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    & img
                    {
                        padding: 2.5%;
                    }
                    & input
                    {
                        width: calc(100% - 3rem);
                        outline: none;
                        border: none;
                        line-height: 2rem;
                    }

                    div
                    {
                        width: 2rem; /* przykładowa szerokość */
                        height: 2rem; /* przykładowa wysokość */
                        background-size: cover;
                        margin: 0.5rem;
                        cursor: pointer;
                        background-image: url('../assets/wyszukaj.svg');
                    }

                    &:hover
                    {
                        div{background-image: url('../assets/wyszukaj-2.svg');}
                    }
                }
            }
            &-zaplecze
            {
                display: flex;
                justify-content: center;
            }
            &-select
            {
                width: 100% !important;
                display: flex;
                justify-content: center;
            }
        }
        &-center
        {
            margin-bottom: 19%;

            div
            {
                width: 2rem; /* przykładowa szerokość */
                height: 2rem; /* przykładowa wysokość */
                background-size: cover;
                margin: 0.5rem;
                cursor: pointer;
            }



            & a
            {
                width: 100%;
                display: flex;
                align-items: center;

                background-color: white;
                border-radius: 1vh;
                margin-bottom: 5%;

                & #cofnij
                {
                    margin-right: 15%;
                }

                #panel-icon { background-image: url('../assets/zamknij-profil.svg'); }
                #archive-icon { background-image: url('../assets/faktury.svg'); }
                #editor-icon { background-image: url('../assets/import.svg'); }
                #payments-icon { background-image: url('../assets/płatnosci.svg'); }
                #platform-icon { background-image: url('../assets/platforma-ecommerce.svg'); }
                #rachunek-icon { background-image: url('../assets/przeslij-info.svg'); }
                #help-icon { background-image: url('../assets/usterka.svg'); }
                #center-icon { background-image: url('../assets/powiadomienia.svg'); }

                .active-icon {
                    &#panel-icon { background-image: url('../assets/zamknij-profil-2.svg'); }
                    &#archive-icon { background-image: url('../assets/faktury-2.svg'); }
                    &#editor-icon { background-image: url('../assets/import-2.svg'); }
                    &#payments-icon { background-image: url('../assets/płatnosci-2.svg'); }
                    &#platform-icon { background-image: url('../assets/platforma-ecommerce-2.svg'); }
                    &#rachunek-icon { background-image: url('../assets/przeslij-info-2.svg'); }
                    &#help-icon { background-image: url('../assets/usterka-2.svg'); }
                    &#center-icon { background-image: url('../assets/powiadomienia-2.svg'); }
                }

                &:hover {
                    #panel-icon { background-image: url('../assets/zamknij-profil-2.svg'); }
                    #archive-icon { background-image: url('../assets/faktury-2.svg'); }
                    #editor-icon { background-image: url('../assets/import-2.svg'); }
                    #payments-icon { background-image: url('../assets/płatnosci-2.svg'); }
                    #platform-icon { background-image: url('../assets/platforma-ecommerce-2.svg'); }
                    #rachunek-icon { background-image: url('../assets/przeslij-info-2.svg'); }
                    #help-icon { background-image: url('../assets/usterka-2.svg'); }
                    #center-icon { background-image: url('../assets/powiadomienia-2.svg'); }
                }
            }
        }
        &-bottom
        {
            margin-bottom: 5%;
            & a
            {
                width: 100%;
                display: flex;
                align-items: center;

                background-color: white;
                border-radius: 1vh;
                margin-bottom: 5%;

                & #cofnij
                {
                    margin-right: 15%;
                }
                & img
                {
                    padding: 2.5%;
                }
            }
        }
        & a
        {
            height: 3rem;
            text-decoration: none;
            color: $font-color;
        }
    }
    #center
    {
        grid-area: center;
        background-color: white;
    }
    #rozwin
    {
        width: 100%;
        display: flex;
        align-items: center;

        background-color: white;
        border-radius: 1vh;
        margin-bottom: 5%;

        & #cofnij
        {
            margin-right: 15%;
        }
        & img
        {
            padding: 5%;
        }
    }
    @media only screen and (min-width: 1000px)
    {
        #main
        {
            min-height: 90vh;
            grid-template-areas:
            "top top"
            "left center";
            grid-template-columns: 30% 70%;
            grid-template-rows: 100px auto;
            padding-top: 1%;
        }
        #top
        {
            margin-bottom: 1%;
        }

        #left
        {
            &-center
            {
                margin-bottom: 30%;
            }
        }
        #zwin
        {
            display: none !important;
        }
    }
</style>