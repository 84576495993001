<template>
    <div id="view">
        <h2>POLITYKA PRYWATNOŚCI STRONY INTERNETOWEJ PAY24HRS</h2>
        <p>1. Dla Właściciela niniejszej strony internetowej, ochrona danych osobowych Użytkowników jest sprawą najwyższej wagi. Dokłada on ogrom starań, aby Użytkownicy czuli się bezpiecznie, powierzając swoje dane osobowe w trakcie korzystania ze strony internetowej.<br>
        2. Użytkownik to osoba fizyczna, osoba prawna albo jednostka organizacyjna, nieposiadająca osobowości prawnej, której ustawa przyznaje zdolność prawną, korzystająca z usług elektronicznych, dostępnych w ramach strony internetowej.<br>
        3. Niniejsza polityka prywatności wyjaśnia zasady i zakres przetwarzania danych osobowych Użytkownika, przysługujące mu prawa, jak też obowiązki administratora tych danych, a także informuje o używaniu plików cookies.<br>
        4. Administrator stosuje najnowocześniejsze środki techniczne i rozwiązania organizacyjne, zapewniające wysoki poziom ochrony przetwarzanych danych osobowych oraz zabezpieczenia przed dostępem osób nieupoważnionych.
        </p>
        <h2>I. ADMINISTRATOR DANYCH OSOBOWYCH</h2>
        <p>Administratorem danych osobowych jest Pay24Hrs, dostępny pod adresem email: pay24hrs.newsletter@gmail.com.</p>
        <h2>II. CEL PRZETWARZANIA DANYCH OSOBOWYCH</h2>
        <p>1. Administrator przetwarza dane osobowe Użytkownika w celach:<br>
            &emsp; &emsp;- założenia konta na stronie,<br>
            &emsp; &emsp;- wysyłania newslettera z informacjami o nowościach i promocjach.
        </p>
        <h2>III. RODZAJ DANYCH</h2>
        <p>1. Administrator przetwarza następujące dane osobowe Użytkownika, których podanie jest niezbędne do korzystania z usług strony:<br>
        &emsp;a. Dane podawane przez Użytkownika opcjonalnie:<br>
        &emsp; &emsp;- adres email;<br>
        &emsp; &emsp;- imię;<br>
        &emsp; &emsp;- nazwa firmy;<br>
        &emsp; &emsp;- kraj;<br>
        &emsp; &emsp;- NIP;<br>
        &emsp; &emsp;- email firmy.
        </p>
        <h2>IV. PODSTAWA PRAWNA PRZETWARZANIA DANYCH OSOBOWYCH</h2>
        <p>1. Dane osobowe są przetwarzane zgodnie z przepisami Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 (RODO).
        </p>
        <h2>V. PRAWA PRZYSŁUGUJĄCE UŻYTKOWNIKOWI</h2>
        <p>Użytkownik ma prawo do:<br>
        - Żądania dostępu do swoich danych osobowych,<br>
        - Sprostowania danych,<br>
        - Wycofania zgody na przetwarzanie danych osobowych,<br>
        - Żądania usunięcia swojego konta oraz danych osobowych,<br>
        - Ograniczenia przetwarzania danych,<br>
        - Przeniesienia danych do innego administratora.
        </p>
        <h2>VI. OKRES PRZECHOWYWANIA DANYCH OSOBOWYCH</h2>
        <p>Dane osobowe są przechowywane tak długo, jak jest to konieczne do realizacji celów, dla których zostały zebrane, oraz zgodnie z obowiązującymi przepisami prawa.
        </p>
        <h2>VII. POWIERZENIE PRZETWARZANIA DANYCH INNYM PODMIOTOM</h2>
        <p>Administrator danych nie udostępnia danych osobowych Użytkowników innym podmiotom, chyba że wymaga tego prawo.
        </p>
        <h2>VIII. BEZPIECZEŃSTWO DANYCH</h2>
        <p>Dane osobowe są przechowywane w bezpiecznej bazie danych, zabezpieczone przed dostępem osób nieuprawnionych.
        </p>
        <h2>VIII. PLIKI COOKIES</h2>
        <p>1. Strona internetowa używa plików cookies (ciasteczka) lub podobną technologię (dalej łącznie nazywane: "cookies") do zbierania informacji o dostępie Użytkownika do strony internetowej (np. za pomocą komputera lub smartfonu) oraz jego preferencjach. Są one wykorzystywane m.in. w celach reklamowych i statystycznych oraz w celu dostosowania strony internetowej do indywidualnych potrzeb Użytkownika.<br>
        2. Pliki cookies to fragmenty informacji, które zawierają unikalny kod referencyjny, który strona internetowa przesyła na urządzenie Użytkownika, w celu przechowywania, a czasem śledzenia informacji, dotyczących używanego urządzenia. Zwykle nie pozwalają one zidentyfikować osoby Użytkownika. Ich głównym zadaniem jest lepsze dopasowanie strony internetowej do Użytkownika.<br>
        3. Niektóre z plików cookies, występujące na stronie internetowej, są dostępne tylko przez czas trwania danej sesji internetowej i wygasają po zamknięciu przeglądarki. Inne pliki cookies służą do zapamiętywania Użytkownika, który po powrocie na stronę internetową, jest na niej rozpoznawany. Są one wówczas zachowywane przed dłuższy czas.<br>
        4. Pliki cookies używane na niniejszej stronie internetowej to:<br>
        Pliki cookies tymczasowe.<br>
        5. Wszystkie pliki cookies, występujące na stronie internetowej, są ustalane przez administratora.<br>
        6. Wszystkie pliki cookies, używane przez niniejszą stronę internetową, są zgodne z obowiązującym prawem Unii Europejskiej.<br>
        7. Większość Użytkowników i niektórych przeglądarek mobilnych automatycznie akceptuje pliki cookies. Jeżeli Użytkownik nie zmieni ustawień, pliki cookies zostaną zapisane w pamięci urządzenia.<br>
        8. Użytkownik może zmienić preferencje, dotyczące akceptacji plików cookies lub zmienić przeglądarkę, aby móc otrzymać za każdym razem stosowne powiadomienie, gdy funkcja cookies jest ustawiona. Aby zmienić ustawienia akceptacji cookies, należy dostosować ustawienia w przeglądarce.<br>
        9. Warto pamiętać, że blokowanie lub usuwanie plików cookies może uniemożliwić pełne korzystanie ze strony internetowej.<br>
        10. Pliki cookies będą wykorzystywane do niezbędnego zarządzania sesją, w tym:<br>
        &emsp;a. Tworzenia specjalnej sesji logowania dla Użytkownika strony internetowej, aby strona zapamiętała, że Użytkownik jest zalogowany, a jego żądania były dostarczane w sposób skuteczny, bezpieczny i spójny;<br>
        &emsp;b. Rozpoznawania Użytkownika, który już wcześniej odwiedził stronę internetową, co pozwala na identyfikację liczby unikalnych użytkowników, którzy skorzystali z serwisu i pozwala upewnić się co do wystarczającej pojemności serwisu dla liczby nowych użytkowników;<br>
        &emsp;c. Rozpoznawania, czy osoba odwiedzająca stronę internetową jest zarejestrowana na stronie internetowej;<br>
        &emsp;d. Rejestrowanie informacji z urządzenia Użytkownika, w tym: pliki cookies, adres IP i informacje o używanej przeglądarce, w celu możliwości diagnozowania problemów, administrowania i śledzenia Użytkowania witryny.<br>
        &emsp;e. Dostosowywania elementów układu szaty graficznej lub zawartości strony internetowej.<br>
        &emsp;f. Zbierania informacji statystycznych o tym, jak Użytkownik korzysta ze strony, w celu możliwości ulepszania witryny i stwierdzenia, które zakresy strony internetowej są najbardziej popularne dla Użytkowników.
        </p>
    </div>
</template>
<script>
export default {
    setup()
    {
        document.title = 'Polityka dotycząca prywatności w Pay24Hrs';
    }
}
</script>
<style lang="scss" scoped>
    #view
    {
        min-height: 80vh;
        p, h2
        {
            line-height: 1.4rem;
        }
    }
</style>