<template>
    <div id="baner">
        <div id="baner-logo" @click="showIt('main')"></div>
        <div id="baner-icon" @click="wrapuj"></div>

        <div id="baner-nav" v-show="wrap">
            <ul id="baner-nav-links">
                <li class="baner-nav-link" id="lang">
                    <a><img id="lang" src="../assets/language-1.svg"></a>
                </li>
                <li class="baner-nav-link" id="B2B" @click="showIt('part5')">
                    <a>Co zyskasz?</a>
                </li>
                <li class="baner-nav-link" id="oNas" @click="showIt('part8')">
                    <a>Newsletter</a>
                </li>
                <li class="baner-nav-link" id="pomoc" @click="showIt('footer')">
                    <a>Pomoc</a>
                </li>
                <li class="baner-nav-link" v-if="!zalogowano">
                    <router-link to="/logowanie">Zaloguj się</router-link>
                </li>
                <li class="baner-nav-link" v-if="!zalogowano">
                    <router-link to="/rejestracja">Rejestracja</router-link>
                </li>
                <li class="baner-nav-link" v-if="zalogowano">
                    <router-link to="/konto">Konto</router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import router from "@/router";
import { ref, onMounted, watch, computed } from "vue";
import { useRoute } from "vue-router";
import VueCookies from 'vue-cookies';

export default {
  setup() {
    const wrap = ref(true);
    const route = useRoute();
    const zalogowano = ref(VueCookies.get("TokenCSRF") ? true : false);

    const isMobile = computed(() => {
      const banerIconStyle = window.getComputedStyle(document.getElementById("baner-icon"));
      return banerIconStyle.getPropertyValue("display") !== "none";
    });

    function wrapuj() {
      wrap.value = !wrap.value;
    }

    function onlyShow(elementId) {
      const el = document.getElementById(elementId);
      const offset = -200;
      const elementTop = el.getBoundingClientRect().top + window.scrollY;
      const targetScrollY = elementTop + offset;
      window.scroll({
        top: targetScrollY,
        behavior: "smooth",
      });
    }

    function showIt(elementId) {
      if (route.path !== '/') {
        router.push("/");
      }
      setTimeout(() => {
        onlyShow(elementId);
      }, 0);
    }

    watch(route, () => {
      zalogowano.value = VueCookies.get("TokenCSRF") ? true : false;
    });

    onMounted(() => {
      if (isMobile.value) {
        wrap.value = false;
      }
    });

    return { wrap, zalogowano, showIt, wrapuj };
  },
};
</script>


<style lang="scss" scoped>
@import '../styles/style.scss';
    *
    {
        color: $font-color;
        text-decoration: none;
    }
    #lang
    {
        height: 1rem;
    }
    #baner
    {

        user-select: none;
        height: 100px;
        width: 100vw;
        z-index: 500;
        position: fixed;
        background-color: white;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-top: 30px;
        justify-content: space-around;
        &-icon
        {
            width: 10%;
            height: 50%;
            background-image: url('../assets/arrow-down.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
        &-logo
        {
            width: 30%;
            height: 40px;
            padding-left: 5%;
            padding-right: 5%;
            background-image: url('../assets/logo.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            cursor: pointer;
        }
        &-nav
        {
            //display: block;
            width: 100%;
            text-align: center;
            &-links
            {
                list-style-type: none;
                display: flex;
                flex-direction: column;
                margin: 0;
                padding: 2%;
                background-color: white;
                border-bottom: 10px solid $border-color;
            }
            .baner-nav-link
            {
                text-transform: uppercase;
                white-space: nowrap;
                margin-top: 5%;
                cursor: pointer;
                & a
                {
                    transition: 0.3s ease-out;
                }
                & :hover
                {
                    color: $main-color;

                }

            }
        }
    }
    @media only screen and (min-width: 1000px)
    {
        #baner
        {
            padding-top: 0;
            align-items: center;
            &-icon
            {
                display: none;
            }
            &-logo
            {
                width: auto;

            }
            &-nav
            {
                display: visible;
                width: auto;
                &-links
                {
                    width: 50vw;
                    list-style-type: none;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin: 0;
                    padding: 0;
                    border: none;
                }
                .baner-nav-link
                {
                    margin-top: 0;
                }
            }
        }
    }
</style>