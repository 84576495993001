<template>
    <div id="view">
        <h2>Ta strona używa plików cookie</h2>
        <p>Polityka naszej strony internetowej dotycząca plików cookie ma na celu poinformowanie użytkowników, że używamy plików cookie w celu poprawy ich komfortu przeglądania oraz w celu kompilacji zbiorczych danych o ruchu w witrynie i interakcjach w witrynie, aby oferować lepsze wrażenia i narzędzia w przyszłości.<br>
        Możemy również korzystać z usług zaufanych stron trzecich, które śledzą te informacje w naszym imieniu. Użytkownicy mogą wyłączyć pliki cookie w ustawieniach swojej przeglądarki internetowej, ale może to wpłynąć na ich możliwość korzystania z niektórych funkcji naszej witryny.<br>
        Korzystając z naszej witryny, użytkownicy wyrażają zgodę na używanie przez nas plików cookie zgodnie z niniejszą polityką.</p>
    </div>
</template>
<script>
export default {
    setup()
    {
        document.title = 'Polityka cookies używanych przez Pay24Hrs';
    }
}
</script>
<style lang="scss" scoped>
    #view
    {
        min-height: 80vh;
        p, h2
        {
            line-height: 1.4rem;
        }
    }
</style>