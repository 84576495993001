<template>
    <div id="line">

    </div>
    <div id="photo">

    </div>
    <div id="main">

        <div id="login">
            <div id="login-in">

                <div id="div-a">
                    <h4>Witaj w Pay24hrs</h4>
                    <h1>Login</h1>
                </div>
                <h4 id="grey">Nie masz konta? <router-link to="/rejestracja">zarejestruj się</router-link></h4>
                <h4>Dane organizacji</h4>
                <form>
                    <input type="text" :placeholder="emailFormPlaceholder" class="mail" v-model="emailForm"/>
                    <input type="password" placeholder="Haslo" class="mail" v-model="passwordForm"/>
                    <router-link to="/set-password" id="password-reset">Nie pamiętasz hasła?</router-link>
                    <input type="button" class="submit" value="ZALOGUJ ⟶" id="send"/>
                </form>
            </div>

        </div>
    </div>
</template>
<script>
import { onMounted, reactive, toRefs, inject } from 'vue';
import axios from "axios";
import router from '@/router';
import VueCookies from 'vue-cookies'

export default {
    setup()
    {
        document.title = 'Logowanie';
        const endpoint = inject("g_endpoint");

        //zawartość formularza login
        var form = reactive(
        {
            emailForm: "",
            passwordForm: "",
            emailFormPlaceholder: "Email"
        })

        //wysłanie logowania do api
        const sendForm = async (event) =>
        {
            event.preventDefault()

            let objectJSON =
            {
                email: form.emailForm,
                password: form.passwordForm
            }

            let info = new FormData()
            info.append("email", objectJSON.email)
            info.append("password", objectJSON.password)
            try
            {
                const response = await axios
                .post(`${endpoint}login/`, info)

                if(response.status === 200)
                {
                    VueCookies.set("TokenCSRF", response.data.csrf_token)
                    VueCookies.set("TokenDRF", response.data.drf_token)
                    VueCookies.set("UserId", response.data.user_id)

                    await router.push("/konto")
                }
                else
                {
                    form.emailForm = "Niepoprawny adres e-mail lub hasło";
                }

            }
            catch (error)
            {
                form.emailFormPlaceholder = "Niepoprawny adres e-mail lub hasło";
                form.emailForm = "";
                console.error(JSON.parse(error.request.response).messages[0])
            }
        }

        onMounted(() =>
        {
            document.getElementById("send").addEventListener("click", sendForm)
        })

        return {...toRefs(form)}
    }
}
</script>
<style lang="scss" scoped>
@import '../styles/style.scss';
    #main
    {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 20px;
        min-height: 100vh;
    }
    #line
    {
        background-color: $main-color;
        position: absolute;
        height: 50px;
        margin-top: 50px;
        z-index: -1;
        width: 100vw;
        margin-left: -10vw;
    }
    #photo
    {
        position: absolute;
        width: 80%;
        height: 50%;
        background-image: url('../assets/zdj-login.png');
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: 90vh;
        display: none;
    }
    #login
    {
        width: 100%;
        & #login-in
        {
            background-color: white;
            width: 100%;
            border-radius: 2vh;
            box-shadow: 0 0 0.8vh 0.01vh $border-color2;
            padding: 5%;

            & form
            {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                & input
                {
                    width: 100%;
                    margin: 0;
                    margin-bottom: 5%;
                }
                & .submit
                {
                    width: 40%;

                }
            }

            form
            {
                width: 100%;
                & .mail
                {
                    width: 100%;
                    padding: 1%;
                    padding-left: 5%;
                    border: none;
                    outline: none;
                    background-color: $grey-color;
                    line-height: 2rem;
                    border-radius: 1vh;
                }

                & .submit
                {
                    //height: 3rem;
                    font-size: 1rem;
                    padding: 3%;
                    padding-left: 5%;
                    padding-right: 5%;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0;

                    transition: 0.2s ease-in-out;
                    border: 1px solid $main-color;
                    border-radius: 25px;
                    text-decoration: none;
                    color: white;
                    background-color: $main-color;
                    font-weight: 200;
                    cursor: pointer;

                    &:hover
                    {
                        background-color: white;
                        color: $main-color;
                    }
                }

                #password-reset
                {
                    text-decoration: none;
                    color: $main-color;
                    width: 100%;
                    padding-left: 1%;
                    margin-top: 0;
                    margin-bottom: 5%;
                    cursor: pointer;
                }
            }
            h4
            {
                text-transform: uppercase;
            }
            h5
            {
                font-size: 1rem;
            }
            h1
            {
                text-transform: capitalize;
            }
            & #grey
            {
                color: $border-color2;
                font-weight: lighter;
                & a
                {
                    color: $main-color;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
            & #div-a
            {
                border-left: 3px solid $main-color;
                padding-left: 3%;

                & h4
                {
                    margin-bottom: 0;
                }
                & h1
                {
                    margin-top: 0;
                }
            }


        }
    }
    @media only screen and (min-width: 1000px)
    {
        #main
        {
            justify-content: right;
        }
        #photo
        {
            display: block;
            width: 40vw;
            margin-top: 150px;
        }
        #login
        {
            width: 35%;
        }
    }
</style>