<template>
    <div id="footer">
        <div id="footer-blanker"></div>
        <div id="footer-main-div">
            <ul id="footer-list-links">
                <li class="footer-list-link">
                    <a id="h1">PAY</a><a id="h2">24H</a><a>rs</a>
                </li>
                <li class="footer-list-link">
                    <router-link to="/bezpieczenstwo">Bezpieczeństwo</router-link>
                </li>
                <li class="footer-list-link">
                    <router-link to="/polityka-prywatnosci">Polityka prywatności</router-link>
                </li>
                <li class="footer-list-link">
                    <router-link to="/zasady-uzytkowania">Zasady użytkowania</router-link>
                </li>
                <li class="footer-list-link">
                    <router-link to="/polityka-cookies">Polityka cookies</router-link>
                </li>
                <li class="footer-list-link">
                    <router-link to="/kontakt">Kontakt</router-link>
                </li>
                <li class="footer-list-link">
                    <a href="https://rmdsolutions.eu/">Created by: <br>RMD Solutions</a>
                </li>
            </ul>
            <ul id="footer-list-images">
                <li>
                    <a id="media-link1" href="https://www.facebook.com/" target="_blank"></a>
                </li>
                <li>
                    <a id="media-link2" href="https://www.twitter.com/" target="_blank"></a>
                </li>
                <li>
                    <a id="media-link3" href="https://www.instagram.com/" target="_blank"></a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
@import '../styles/style.scss';
    #footer
    {
        user-select: none;
        height: 400px;
        width: 100vw;
        background-color: $main-color;
        display: flex;
        justify-content: space-around;
        align-items: center;
        bottom: 0;
        &-blanker
        {
            display: none;
        }
        &-main-div
        {
            width: 80%;
            height: 80%;
            display: flex;
            flex-direction: row;
            gap: 5%;
            & #footer-list-images
            {
                li
                {
                    display: flex;
                    justify-content: center;
                    height: 20%;
                    div, a
                    {
                        height: 100%;
                        padding: 20%;
                    }
                }
            }
            & ul
            {
                width: 50%;
                height: 100%;
                list-style-type: none;
                display: flex;
                flex-direction: column;
                gap: 5%;
                padding: 0;
                & li
                {
                    text-align: left;
                    height: 15%;
                    display: flex;
                    align-items: baseline;
                    & a
                    {
                        color: white;
                        text-transform: uppercase;
                        text-decoration: none;
                    }
                    & div
                    {
                        padding: 10%;
                        //max-height: 1vh;
                        width: 0;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        cursor: pointer;
                        border-radius: 100vw;
                    }
                    & #media-link1
                    {
                        background-image: url('../assets/facebok.svg');
                        background-position: center;
                        background-repeat: no-repeat;
                        &:hover
                        {
                            background-image: url('../assets/facebook-active.svg');
                        }
                    }
                    & #media-link2
                    {
                        background-image: url('../assets/twitter.svg');
                        background-position: center;
                        background-repeat: no-repeat;
                        &:hover
                        {
                            background-image: url('../assets/twitter\ active.svg');
                        }
                    }
                    & #media-link3
                    {
                        background-image: url('../assets/instagram.svg');
                        background-position: center;
                        background-repeat: no-repeat;
                        &:hover
                        {
                            background-image: url('../assets/instagram-active.svg');
                        }
                    }
                }
            }


            #h1
            {
                font-size: 2rem;
                color: white;
            }
            #h2
            {
                font-size: 1.5rem;
                color: white;
            }
        }
    }

    @media only screen and (min-width: 1000px)
    {
        #footer
        {
            &-blanker
            {
                display: block;
            }
            &-main-div
            {
                width: 30%;
                align-items: center;
                & #footer-list-images
                {
                    li div
                    {
                        padding: 10%;
                    }
                }
            }
        }
    }
</style>