<template>
    <div id="view">
        <div id="view-file">
            <form>
                <label for="import-file">
                    <input type="file" id="import-file" accept=".xml" multiple/>
                    <img src="@/assets/import-2.svg" />
                </label>
            </form>
            <a>Wybierz plik .xml</a>
        </div>
        <div id="view-archive">
            <div id="view-archive-bottom">
                <div class="faktura" v-for="faktura in fakturyList" :key="faktura">
                    <a class="make-space"><input type="checkbox" :id="`checkbox${faktura.fakturaId}`"
                            class="check check1" /></a>
                    <a class="fakturaName" @click="editElement(faktura.fakturaId)">{{ faktura.fakturaId }}. {{ faktura.fakturaTitle }}</a>
                    <a class="make-space" :id="`Edytuj${faktura.fakturaId}`">Edytuj</a>
                    <a class="make-space" :id="`Usun${faktura.fakturaId}`">Usuń</a>
                </div>
            </div>
        </div>
        <div id="view-main">
            <div id="view-main-header">
                <h1>Dodaj fakturę VAT</h1>
            </div>
            <div id="view-main-data">
                <form>
                    <div id="seria-faktury-div">
                        <label for="seria-faktury">Seria faktury</label>
                        <select id="seria=faktury" v-model="seriaFaktury">
                            <option disabled hidden selected value="">Wybierz serię faktury</option>
                            <option>PL</option>
                            <option>DE</option>
                        </select>
                    </div>
                    <div id="numer-faktury-div">
                        <label for="numer-faktury">Numer faktury</label>
                        <input type="text" id="numer-faktury" v-model="numerFaktury" placeholder="Wpisz numer faktury" />
                    </div>
                    <div id="data-wystawienia-div">
                        <label for="data-wystawienia">Data wystawienia</label>
                        <input type="date" id="numer-faktury" v-model="dataWystawienia" />
                    </div>
                    <div id="data-sprzedazy-div">
                        <label for="data-sprzedazy">Data sprzedaży</label>
                        <input type="date" id="data-sprzedazy" v-model="dataSprzedazy" />
                    </div>
                    <div id="termin-platnosci-div">
                        <label for="termin-platnosci">Termin płatności</label>
                        <input type="date" id="termin-platnosci" v-model="terminPlatnosci" />
                    </div>
                    <div id="sposob-platnosci-div">
                        <label for="sposob-platnosci">Sposób płatności</label>
                        <select id="sposob-platnosci" v-model="sposobPlatnosci">
                            <option disabled hidden selected value="">Wybierz sposób płatności</option>
                            <option>Gotówka</option>
                            <option>Przelew</option>
                        </select>
                    </div>
                    <div id="waluta-div">
                        <label for="waluta">Waluta</label>
                        <select id="waluta" v-model="waluta">
                            <option disabled hidden selected value="">Wybierz walutę</option>
                            <option>PLN</option>
                            <option>EUR</option>
                        </select>
                    </div>
                    <div id="miejsce-wystawienia-div">
                        <label for="miejsce-wystawienia">Miejsce wystawienia</label>
                        <input type="text" id="miejsce-wystawienia" v-model="miejsceWystawienia"
                            placeholder="Wpisz miejsce wystawienia" />
                    </div>
                    <div id="uwagi-div">
                        <label for="uwagi">Uwagi</label>
                        <input type="text" id="uwagi" v-model="uwagi" placeholder="Wpisz ewentualne uwagi" />
                    </div>
                    <div id="checkboxy-div">
                        <label for="checkbox1"><input type="checkbox" id="checkbox1" class="check" v-model="podzielonaPlatnosc" />Mechanizm podzielonej płatności</label>
                        <label for="checkbox2"><input type="checkbox" id="checkbox2" class="check" v-model="odwrotneObciazenie" />Odwrotne obciążenie</label>
                    </div>
                </form>
            </div>

            <div id="view-main-products">
                <div id="products-header">
                    <a>Usuń</a>
                    <a class="wide-a">Nazwa towaru/usługi</a>
                    <a>GTU</a>
                    <a>PKWiU</a>
                    <a>Ilość</a>
                    <a>j.m.</a>
                    <a>Cena netto</a>
                    <a>Rabat w %</a>
                    <a>Stawka VAT</a>
                    <a>Rabat</a>
                    <a>Wartość netto</a>
                    <a>Wartość VAT</a>
                    <a>Brutto</a>
                </div>
                <div class="products-list" v-for="product, index in products" :key="index">
                    <a class="remove-product">-</a>
                    <input type="text" :id="`nazwa${product}`" v-model="productValues[index].nazwa" class="wide-a" />
                    <input type="text" :id="`gtu${product}`" v-model="productValues[index].gtu" />
                    <input type="text" :id="`pkwiu${product}`" v-model="productValues[index].pkwiu" />
                    <input type="number" :id="`ilosc${product}`" v-model="productValues[index].ilosc" />
                    <input type="text" :id="`jm${product}`" v-model="productValues[index].jm" />
                    <input type="number" :id="`cenaNetto${product}`" v-model="productValues[index].cenaNetto" />
                    <input type="number" :id="`rabatPr${product}`" v-model="productValues[index].rabatPr" />
                    <input type="number" :id="`stawkaVat${product}`" v-model="productValues[index].stawkaVat" />
                    <input type="number" disabled :id="`rabatX${product}`" v-model="productValues[index].rabatX" />
                    <input type="number" disabled :id="`wartoscNetto${product}`"
                        v-model="productValues[index].wartoscNetto" />
                    <input type="number" disabled :id="`wartoscVat${product}`" v-model="productValues[index].wartoscVat" />
                    <input type="number" disabled :id="`brutto${product}`" v-model="productValues[index].brutto" />
                </div>
                <div id="products-add">
                    <img src="@/assets/przeslij-info-2.svg" id="products-add-img" />
                </div>
                <div id="products-summary">
                    <a></a>
                    <a id="super-wide-a">razem:</a>
                    <a>{{ sumaRabatow }}</a>
                    <a>{{ wartoscNetto }}</a>
                    <a>{{ wartoscVat }}</a>
                    <a>{{ wartoscBrutto }}</a>
                </div>
            </div>

            <div id="view-main-people">
                <div id="nabywca">
                    <form>
                        <a>Nabywca</a>
                        <label>Nazwa nabywcy<input type="text" id="nabywca-nazwa" v-model="nazwaNabywcy" /></label>
                        <label>Adres nabywcy: ulica<input type="text" id="nabywca-nazwa" v-model="ulicaNabywcy" /></label>
                        <label>Adres nabywcy: kod pocztowy<input type="text" id="nabywca-nazwa"
                                v-model="kodNabywcy" /></label>
                        <label>Adres nabywcy: miasto<input type="text" id="nabywca-nazwa" v-model="miastoNabywcy" /></label>
                        <label>NIP lub PESEL nabywcy<input type="text" id="nabywca-nazwa" v-model="nipNabywcy" /></label>
                    </form>
                </div>
                <div id="odbiorca">
                    <form>
                        <a>Odbiorca</a>
                        <label>Nazwa odbiorcy<input type="text" id="odbiorca-nazwa" v-model="nazwaOdbiorcy" /></label>
                        <label>Adres odbiorcy: ulica<input type="text" id="odbiorca-nazwa"
                                v-model="ulicaOdbiorcy" /></label>
                        <label>Adres odbiorcy: kod pocztowy<input type="text" id="odbiorca-nazwa"
                                v-model="kodOdbiorcy" /></label>
                        <label>Adres odbiorcy: miasto<input type="text" id="odbiorca-nazwa"
                                v-model="miastoOdbiorcy" /></label>
                        <label>NIP lub PESEL odbiorcy<input type="text" id="odbiorca-nazwa" v-model="nipOdbiorcy" /></label>
                    </form>
                </div>
            </div>
        </div>

        <div id="view-buttons">
            <a id="zapisz">Zapisz</a>
            <a id="pobierz-i-zapisz">Pobierz i zapisz</a>
        </div>
        <div id="error-code">{{ sendError }}</div>
    </div>
</template>
<script>
import { computed, onMounted, reactive, ref, toRefs, inject } from 'vue';
import axios from 'axios';
import VueCookies from 'vue-cookies';

export default {
    props:
    {
        id: {
            type: String,
        },
    },
    setup(props) {
        document.title = 'Edytor Faktur';
        const endpoint = inject("g_endpoint")
        var products = ref(0); //ilość produktów w fakturze
        var productValues = ref([]); //wartości pól produktów
        //var fileList = ref(); //zmieni się w tablicę plików
        var fileToSend = ref([]); //lista plików, które są zaimportowane. Pushowana w sendConvertedFile
        var listOfFiles = ref([]); //lista pełnych faktur z response

        var sendError = ref("") //błąd zapisania faktury w api

        //faktury
        var fakturyList = ref([]); //lista faktur
        var search = ref(""); //wyszukiwana wartość
        var factureId = ref("") //id naszej faktury, potrzebne do pdf

        //formularz
        var everyData = reactive({
            seriaFaktury: "",
            dataWystawienia: "",
            terminPlatnosci: "",
            waluta: "",
            numerFaktury: "",
            dataSprzedazy: "",
            sposobPlatnosci: "",
            miejsceWystawienia: "",
            uwagi: "",
            podzielonaPlatnosc: false,
            odwrotneObciazenie: false,

            nazwaNabywcy: "",
            ulicaNabywcy: "",
            kodNabywcy: "",
            miastoNabywcy: "",
            nipNabywcy: "",

            nazwaOdbiorcy: "",
            ulicaOdbiorcy: "",
            kodOdbiorcy: "",
            miastoOdbiorcy: "",
            nipOdbiorcy: "",
        })

        //obliczanie wartości formularza
        var sumData = reactive({
            wartoscNetto: computed(() => {
                let sum = 0;
                productValues.value.forEach((element) => {
                    sum += element.wartoscNetto;
                })
                return parseFloat(sum.toFixed(2));
            }),
            wartoscVat: computed(() => {
                let sum = 0;
                productValues.value.forEach((element) => {
                    sum += element.wartoscVat;
                })
                return parseFloat(sum.toFixed(2));
            }),
            wartoscBrutto: computed(() => {
                let sum = 0;
                productValues.value.forEach((element) => {
                    sum += element.brutto;
                })

                return parseFloat(sum.toFixed(2));
            }),
            sumaRabatow: computed(() => {
                let sum = 0;
                productValues.value.forEach((element) => {
                    if (element.rabatX)
                        sum += parseFloat(element.rabatX);
                })
                return parseFloat(sum.toFixed(2));
            }),
        })

        //usunięcie produktu faktury
        const removeItem = (item) => {
            productValues.value.splice(item, 1);
            products.value--;
        }

        //dodanie produktu faktury
        var highestId = ref(0);
        const addItem = () => {
            products.value++;
            highestId.value++;
            const uniqueId = Number(highestId.value)
            productValues.value.push(
                {
                    id: uniqueId,
                    nazwa: "",
                    gtu: "",
                    pkwiu: "",
                    ilosc: "",
                    jm: "",
                    cenaNetto: "",
                    rabatPr: "",
                    stawkaVat: ""
                }
            )
            const item = productValues.value.find(item => item.id === uniqueId);
            item.wartoscNetto = computed(() => {
                return parseFloat((item.cenaNetto * item.ilosc * (1 - (item.rabatPr / 100))).toFixed(2));
            });
            item.wartoscVat = computed(() => {
                return parseFloat((item.stawkaVat / 100 * item.wartoscNetto).toFixed(2));
            });
            item.brutto = computed(() => {
                return parseFloat((item.wartoscNetto + item.wartoscVat).toFixed(2));
            });
            item.rabatX = computed(() => {
                return parseFloat(parseFloat(item.wartoscNetto).toFixed(2) - parseFloat((item.cenaNetto * item.ilosc).toFixed(2))).toFixed(2);
            });

            let minusy = document.getElementsByClassName("remove-product");
            setTimeout(() => {
                minusy = Array.from(minusy);
                let itemek = minusy[minusy.length - 1];
                let constIndex = minusy.length - 1
                itemek.addEventListener("click", () => { removeItem(constIndex) })

            }, 0);
        }

        //upload faktury do api
        const sendForm = async () => {
            let objectJSON =
            {
                series: everyData.seriaFaktury,
                issue_date: everyData.dataWystawienia,
                payment_date: everyData.terminPlatnosci,
                currency: everyData.waluta,
                number: everyData.numerFaktury,
                sale_date: everyData.dataSprzedazy,
                payment_method: everyData.sposobPlatnosci,
                place: everyData.miejsceWystawienia,
                comments: everyData.uwagi,
                is_split_payment: everyData.podzielonaPlatnosc,
                is_reverse_charge: everyData.odwrotneObciazenie,
                user: VueCookies.get("UserId"),

                total_discount: sumData.sumaRabatow,
                total_netto_value: sumData.wartoscNetto,
                total_vat_value: sumData.wartoscVat,
                total_brutto: sumData.wartoscBrutto,
            }

            let objectJSONBuyer =
            {
                user: VueCookies.get("UserId"),
                name: everyData.nazwaNabywcy,
                street_address: everyData.ulicaNabywcy,
                zip_code: everyData.kodNabywcy,
                city: everyData.miastoNabywcy,
                identification_number: everyData.nipNabywcy
            }

            let objectJSONSeller =
            {
                user: VueCookies.get("UserId"),
                name: everyData.nazwaOdbiorcy,
                street_address: everyData.ulicaOdbiorcy,
                zip_code: everyData.kodOdbiorcy,
                city: everyData.miastoOdbiorcy,
                identification_number: everyData.nipOdbiorcy
            }

            let sendDataBuyer = JSON.stringify(objectJSONBuyer);
            let sendDataSeller = JSON.stringify(objectJSONSeller);

            try
            {
                const responseBuyer = await axios
                .post(`${endpoint}entities/`, sendDataBuyer, {
                    withCredentials: true, headers:
                    {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': `${VueCookies.get('TokenCSRF')}`,
                        'Authorization': `Token ${VueCookies.get('TokenDRF')}`
                    }
                });

                const responseSeller = await axios
                .post(`${endpoint}entities/`, sendDataSeller, {
                    withCredentials: true, headers:
                    {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': `${VueCookies.get('TokenCSRF')}`,
                        'Authorization': `Token ${VueCookies.get('TokenDRF')}`
                    }
                });

                objectJSON.buyer = responseBuyer.data.id
                objectJSON.seller = responseSeller.data.id

                let sendData = JSON.stringify(objectJSON);

                const response = await axios
                .post(`${endpoint}factures/`, sendData, {
                    withCredentials: true, headers:
                    {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': `${VueCookies.get('TokenCSRF')}`,
                        'Authorization': `Token ${VueCookies.get('TokenDRF')}`
                    }
                });

                factureId.value = response.data.id

                for (const [index, element] of productValues.value.entries())
                {
                    console.log(element)
                    let productToSend =
                    {
                        facture: response.data.id,
                        lp: index,
                        name: element.nazwa,
                        gtu: element.gtu,
                        pkwiu: element.pkwiu,
                        quantity: element.ilosc,
                        unit_of_measure: element.jm,
                        netto_price: element.cenaNetto,
                        discount: element.rabatPr,
                        vat_rate: element.stawkaVat,
                        vat_value: element.wartoscVat,
                        netto_value: element.wartoscNetto,
                        brutto: element.brutto,
                    }
                    if(productToSend.name == "") productToSend.name = " "
                    if(productToSend.gtu == "") productToSend.gtu = " "
                    if(productToSend.pkwiu == "") productToSend.pkwiu = " "
                    if(productToSend.quantity == "") productToSend.quantity = "0"
                    if(productToSend.unit_of_measure == "") productToSend.unit_of_measure = " "
                    if(productToSend.netto_price == "") productToSend.netto_price = "0"
                    if(productToSend.discount == "") productToSend.discount = "0"
                    if(productToSend.vat_rate == "") productToSend.vat_rate = "0"

                    let data = JSON.stringify(productToSend)

                    await axios
                    .post(`${endpoint}products/`, data,
                    {
                        withCredentials: true, headers:
                        {
                            'Content-Type': 'application/json',
                            'X-CSRFToken': `${VueCookies.get('TokenCSRF')}`,
                            'Authorization': `Token ${VueCookies.get('TokenDRF')}`
                        }
                    });
                }

                sendError.value = "Faktura zapisana poprawnie"
                document.getElementById("error-code").style = "color: green"
            }
            catch (error)
            {
                if (error.response.status == 400)
                {
                    sendError.value = "Nieprawidłowe dane. Sprawdź wszystkie rekordy"
                    document.getElementById("error-code").style = "color: red"
                }
                console.error(error);
            }
        }

        // tworzenie listy faktur do edycji
        var highestFactureId = ref(0)

        //z pliku
        const sendConvertedFile = async () =>
        {
            try
            {
                for (const element of fileToSend.value)
                {
                    let response = await axios.post(`${endpoint}get_factures_data/`, element,
                    {
                        withCredentials: true,
                        headers: { 'Content-Type': 'application/xml' }
                    });
                    listOfFiles.value.push(response.data[0]);
                    highestFactureId.value++;

                    fakturyList.value.push(
                    {
                        fakturaId: highestFactureId.value,
                        fakturaTitle: listOfFiles.value[listOfFiles.value.length - 1].number
                    });

                    setTimeout(addListeners2, 0);
                }
            }
            catch (error)
            {
                console.error(error);
            }
        }

        //z linku
        const createListFromProps = async () => {
            let propTable = props.id.split(",")
            try
            {
                for (const id of propTable)
                {
                    const response = await axios
                    .get(`${endpoint}factures/${id}/`,
                    {
                        withCredentials: true,
                        headers:
                        {
                            'X-CSRFToken': `${VueCookies.get('TokenCSRF')}`,
                            'Authorization': `Token ${VueCookies.get('TokenDRF')}`
                        }
                    });

                    listOfFiles.value.push(response.data);
                    highestFactureId.value++;

                    fakturyList.value.push(
                    {
                        fakturaId: highestFactureId.value,
                        fakturaTitle: listOfFiles.value[listOfFiles.value.length - 1].number
                    });

                    setTimeout(addListeners2, 0);
                }
            }
            catch (error)
            {
                console.error(error);
            }
        }

        // dodawanie listenerów do faktur - ale tylko raz
        const listenersAdded = {
            'Usun': {},
            'Edytuj': {}
        }
        const addListeners2 = () => {

            fakturyList.value.forEach(element =>
            {

                if (!listenersAdded['Usun'][element.fakturaId] && document.getElementById(`Usun${element.fakturaId}`))
                {
                    document.getElementById(`Usun${element.fakturaId}`).addEventListener("click", () =>
                    {
                        removeElement(element.fakturaId)
                    });
                    listenersAdded['Usun'][element.fakturaId] = true;
                }

                if (!listenersAdded['Edytuj'][element.fakturaId] && document.getElementById(`Edytuj${element.fakturaId}`))
                {
                    document.getElementById(`Edytuj${element.fakturaId}`).addEventListener("click", () =>
                    {
                        editElement(element.fakturaId)
                    });
                    listenersAdded['Edytuj'][element.fakturaId] = true;
                }
            });
        }

        // usuwanie faktury
        const removeElement = (id) =>
        {
            const indexToRemove = fakturyList.value.findIndex((item) => item.fakturaId === id);
            if (indexToRemove !== -1) {
                fakturyList.value.splice(indexToRemove, 1);
            }
        }

        // edycja faktury
        const editElement = async (passedId) =>
        {
            let id = passedId - 1
            fetchData3(id)
        }

        // edycja faktury ciąg dalszy
        const fetchData3 = async (id) =>
        {
            let received = listOfFiles.value[id]

            everyData.seriaFaktury = received.series;
            everyData.numerFaktury = received.number;
            everyData.dataWystawienia = received.issue_date;
            everyData.dataSprzedazy = received.sale_date;
            everyData.terminPlatnosci = received.payment_date;
            everyData.sposobPlatnosci = received.payment_method;
            everyData.waluta = received.currency;
            everyData.miejsceWystawienia = received.place;
            everyData.uwagi = received.comments;
            everyData.podzielonaPlatnosc = received.is_split_payment;
            everyData.odwrotneObciazenie = received.is_reverse_charge;

            if (received.buyer) {
                if (Number.isInteger(received.buyer))
                {
                    let response = await axios
                        .get(`${endpoint}entities/${received.buyer}/`,
                        {
                            withCredentials: true,
                            headers:
                            {
                                'X-CSRFToken': `${VueCookies.get('TokenCSRF')}`,
                                'Authorization': `Token ${VueCookies.get('TokenDRF')}`
                            }
                        });
                    let buyerData = response.data;

                    everyData.nazwaNabywcy = buyerData.name;
                    everyData.ulicaNabywcy = buyerData.street_address;
                    everyData.kodNabywcy = buyerData.zip_code;
                    everyData.miastoNabywcy = buyerData.city;
                    everyData.nipNabywcy = buyerData.identification_number;
                }
                else
                {
                    everyData.nazwaNabywcy = received.buyer.name;
                    everyData.ulicaNabywcy = received.buyer.street_address;
                    everyData.kodNabywcy = received.buyer.zip_code;
                    everyData.miastoNabywcy = received.buyer.city;
                    everyData.nipNabywcy = received.buyer.nip;
                }
            }
            else {
                everyData.nazwaNabywcy = "";
                everyData.ulicaNabywcy = "";
                everyData.kodNabywcy = "";
                everyData.miastoNabywcy = "";
                everyData.nipNabywcy = "";
            }

            if (received.seller)
            {
                if (Number.isInteger(received.seller))
                {
                    let response = await axios
                        .get(`${endpoint}entities/${received.seller}/`,
                        {
                            withCredentials: true,
                            headers:
                            {
                                'X-CSRFToken': `${VueCookies.get('TokenCSRF')}`,
                                'Authorization': `Token ${VueCookies.get('TokenDRF')}`
                            }
                        });
                    let sellerData = response.data;

                    everyData.nazwaOdbiorcy = sellerData.name;
                    everyData.ulicaOdbiorcy = sellerData.street_address;
                    everyData.kodOdbiorcy = sellerData.zip_code;
                    everyData.miastoOdbiorcy = sellerData.city;
                    everyData.nipOdbiorcy = sellerData.identification_number;
                }
                else {
                    everyData.nazwaOdbiorcy = received.seller.name;
                    everyData.ulicaOdbiorcy = received.seller.street_address;
                    everyData.kodOdbiorcy = received.seller.zip_code;
                    everyData.miastoOdbiorcy = received.seller.city;
                    everyData.nipOdbiorcy = received.seller.nip;
                }
            }
            else
            {
                everyData.nazwaOdbiorcy = "";
                everyData.ulicaOdbiorcy = "";
                everyData.kodOdbiorcy = "";
                everyData.miastoOdbiorcy = "";
                everyData.nipOdbiorcy = "";
            }

            let saveKey = 0

            if (received.products)
            {
                let productsArray = received.products;
                productsArray = JSON.parse(JSON.stringify(productsArray));
                for (const key in productsArray)
                {
                    if(!productValues.value[key])
                    {
                        addItem()
                    }
                    productValues.value[key].nazwa = productsArray[key].name
                    productValues.value[key].gtu = productsArray[key].gtu
                    productValues.value[key].pkwiu = productsArray[key].pkwiu
                    productValues.value[key].ilosc = productsArray[key].quantity
                    productValues.value[key].jm = productsArray[key].unit_of_measure
                    productValues.value[key].cenaNetto = productsArray[key].netto_price
                    productValues.value[key].rabatPr = productsArray[key].discount
                    productValues.value[key].stawkaVat = productsArray[key].vat_rate
                    saveKey = key;
                }
            }
            else
            {
                let response = await axios
                .get(`${endpoint}products/?facture=${received.id}`, {
                    withCredentials: true,
                    headers: {
                        'X-CSRFToken': `${VueCookies.get('TokenCSRF')}`,
                        'Authorization': `Token ${VueCookies.get('TokenDRF')}`
                    }
                });
                let productsArray = response.data
                for (const key in productsArray)
                {
                    if(!productValues.value[key])
                    {
                        addItem()
                    }
                    productValues.value[key].nazwa = productsArray[key].name
                    productValues.value[key].gtu = productsArray[key].gtu
                    productValues.value[key].pkwiu = productsArray[key].pkwiu
                    productValues.value[key].ilosc = productsArray[key].quantity
                    productValues.value[key].jm = productsArray[key].unit_of_measure
                    productValues.value[key].cenaNetto = productsArray[key].netto_price
                    productValues.value[key].rabatPr = productsArray[key].discount
                    productValues.value[key].stawkaVat = productsArray[key].vat_rate
                    saveKey = key;
                }

            }
            for(let i = productValues.value.length-1; i > saveKey; i--)
            {
                removeItem(i)
            }

        }

        // przesył pliku i listenery edytora
        const addListeners = () => {
            document.getElementById("products-add-img").addEventListener("click", () => { addItem() });
            document.getElementById("zapisz").addEventListener("click", sendForm);
            //document.getElementById("pobierz").addEventListener("click", downloadElement);
            document.getElementById("pobierz-i-zapisz").addEventListener("click", sendAndDownload);

            //Plik
            const fileSelector = document.getElementById('import-file');

            fileSelector.addEventListener('change', async (event) => {
                const files = event.target.files;
                fileToSend.value = []; // Resetowanie listy plików do wysłania

                // Użycie map do przekształcenia FileList w tablicę Promise'ów, które wczytują pliki
                const readFilesPromises = Array.from(files).map(file => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onload = (evt) => resolve(evt.target.result); // Rozwiązanie Promise wartością wczytanego tekstu
                        reader.onerror = reject; // Odrzucenie Promise w przypadku błędu
                        reader.readAsText(file); // Rozpoczęcie wczytywania pliku
                    });
                });

                try {
                    // Oczekiwanie na wczytanie wszystkich plików
                    const loadedFiles = await Promise.all(readFilesPromises);
                    // Każdy wczytany plik jest dodawany do listy plików do wysłania
                    loadedFiles.forEach(fileContent => fileToSend.value.push(fileContent));
                    // Tutaj wywołujesz funkcję, która przetwarza wczytane pliki (np. wysyła je na serwer)
                    // Należy zdefiniować tę funkcję wcześniej, analogicznie do sendConvertedFile w poprzednim przykładzie
                    await sendConvertedFile();
                } catch (error) {
                    console.error("Błąd podczas wczytywania plików:", error);
                }
            });

        }

        const downloadElement = async (id) =>
        {
            let wasGenerated = false //on sprawdza, czy była już wygenerowana
            let generatedId = id //on szuka id tego co zostało już wygenerowane, i to właśnie sprawia mi problem

            try
            {
                const response = await axios
                .get(`${endpoint}generated_factures/?facture=${id}`,//2
                {
                    withCredentials: true,
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': `${VueCookies.get('TokenCSRF')}`,
                        'Authorization': `Token ${VueCookies.get('TokenDRF')}`
                    }
                });
                if(response.data[0])
                {
                    wasGenerated = true
                    generatedId = response.data[0].id
                }
            }
            catch(error)
            {
                console.error(error)
                wasGenerated = false
            }

            try
            {
                let xD =
                {
                    xD: "xD"
                }
                let send = JSON.stringify(xD)


                if(!wasGenerated)
                {
                    const response = await axios
                    .post(`${endpoint}factures/${generatedId}/generate/`, send,
                    {
                        withCredentials: true, headers:
                        {
                            'Content-Type': 'application/json',
                            'X-CSRFToken': `${VueCookies.get('TokenCSRF')}`,
                            'Authorization': `Token ${VueCookies.get('TokenDRF')}`
                        }
                    });
                    generatedId = response.data.generated_facture_id
                    console.log(response)
                }

                const downloadResponse = await axios
                .get(`${endpoint}generated_factures/${generatedId}/download/`,//2
                {
                    withCredentials: true,
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': `${VueCookies.get('TokenCSRF')}`,
                        'Authorization': `Token ${VueCookies.get('TokenDRF')}`
                    }
                });
                console.log(downloadResponse)
                window.open(`${endpoint}generated_factures/${generatedId}/download/`, `_blank`)
            }
            catch(error)
            {
                console.error(error)
            }

        }

        const sendAndDownload = async () =>
        {
            await sendForm();
            setTimeout(async () => {await downloadElement(factureId.value)}, 10)
        }

        onMounted(() =>
        {
            addListeners();
            addItem();

            if (props.id)
            {
                createListFromProps()
            }
        })

        return { products, productValues, ...toRefs(everyData), ...toRefs(sumData), fakturyList, search, sendError, editElement }
    }
}
</script>
<style lang="scss" scoped>
@import 'src/styles/style.scss';

#view {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;


    & .fakturaName {
        word-break: break-all;
        cursor: pointer;
    }

    & .check {
        transform: scale(2);
    }

    &-search {
        width: 50%;
        margin-top: 5%;
        margin-bottom: 5%;

        &-type {
            width: 47.5%;
            padding: 1%;
            padding-left: 5%;
            border: none;
            outline: none;
            background-color: $grey-color;
            line-height: 2rem;
            margin-right: 5%;
            border-radius: 1vh;
        }

        &-button {
            width: 47.5%;
            padding: 1% !important;
            border: none;
            outline: none;
            background-color: $main-color !important;
            color: white !important;
            line-height: 2rem;
            font-weight: bold;
            border-radius: 1vh;
        }

        & form {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
        }
    }

    &-archive {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        //margin-bottom: 2%;
        &-top {
            background-color: $main-color;
            padding: 5%;
            width: 100%;
            border-radius: 1vh;
            color: white;
            display: flex;
            justify-content: space-between;
            margin-bottom: 2%;
        }

        &-bottom {
            width: 100%;

            & .faktura {
                margin-top: 2%;
                background-color: $grey-color;
                border-radius: 1vh;
                padding-top: 2%;
                padding-bottom: 2%;
                width: 100%;
                color: $font-color;
                display: flex;
                justify-content: space-between;
                align-items: center;

                & .make-space {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                & .fakturaName {
                    width: 100%;
                }
            }
        }
    }



    &-file {
        display: flex;
        flex-direction: column;
        width: 40%;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 5%;
        margin-bottom: 5%;

        & form {
            height: 100px;
        }

        & a {
            margin-top: 5%;
        }

        & form,
        a {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: space-between;
            white-space: nowrap;
            margin-right: 5%;

            & input {
                display: none;
            }

            & label {
                width: 100%;
                text-align: center;
            }

            & img {
                width: auto;
                height: 100%;
                cursor: pointer;
                border-radius: 100vw;
            }
        }
    }

    &-main {
        margin-top: 2%;
        width: 90%;

        &-data {
            width: 100%;

            & form {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                & div {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 2%;

                    & label {
                        font-weight: bold;
                        margin-bottom: 2%;
                    }
                }

                & #uwagi-div,
                #checkboxy-div {
                    width: 100%;
                }

                & #checkboxy-div {
                    flex-direction: column;
                    align-items: space-around;
                    justify-content: center;

                    & label {
                        margin-bottom: 0;
                        width: 50%;
                        white-space: nowrap;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }

        &-products {
            display: flex;
            //flex-wrap: nowrap;
            overflow-x: auto;

            //max-height: 100vh;
            //justify-content: center;
            & #products-header,
            #products-summary {
                background-color: $main-color;
                border-radius: 1vh;
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                width: auto;
                //gap: 2%;
                height: auto;
                flex-shrink: 0;
                flex-direction: column;

                & a {
                    border: 1px solid white;
                    width: 100%;
                    display: flex;
                    padding: 1%;
                    padding-top: 5%;
                    padding-bottom: 5%;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    color: white;
                    height: 3rem;
                }
            }

            & .products-list {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: space-between;
                width: auto;
                min-width: 40%;
                margin-left: 5%;

                & a,
                input {
                    border-radius: 1vh;
                    width: 100%;
                    display: flex;
                    padding: 1%;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    color: $font-color;
                    background-color: $grey-color;
                    margin: 0;
                    height: 3rem;
                    border: 1px solid white;
                }
            }

            & #products-summary {
                flex-shrink: 0;
                width: 40%;

                & a {
                    justify-content: center;
                }

                & #super-wide-a {
                    height: 65%;
                    text-align: left;
                }
            }

            & #products-add {
                width: auto;
                height: auto;
                //border-radius: 100vw;
                display: flex;
                justify-content: center;
                align-items: center;
                //margin-top: 2%;
                margin-left: 5%;
                margin-right: 5%;

                & img {
                    border-radius: 100vw;
                }
            }
        }

        &-people {
            margin-top: 5%;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            & div {
                width: 100%;
                margin-bottom: 5%;

                & form {

                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    & label {
                        margin-bottom: 4%;
                        font-weight: bold;
                    }

                    & a {
                        margin-bottom: 5%;
                        font-weight: bold;
                        font-size: 1.2rem;
                    }

                    & input {
                        margin-top: 1%;
                    }
                }
            }
        }
    }

    & #import-button {
        padding: 1%;
        border: none;
        outline: none;
        background-color: $main-color;
        color: white;
        font-weight: bold;
        border-radius: 1vh;
        line-height: 2rem;
    }

    &-buttons {
        width: 90%;
        display: flex;
        justify-content: space-between;

        a
        {
            width: 47.5%;
            padding: 1%;
            outline: none;
            background-color: $main-color;
            color: white;
            line-height: 2rem;
            font-weight: bold;
            border-radius: 1vh;

            display: flex;
            justify-content: center;
            align-items: center;

            transition: 0.2s ease-in-out;
            border: 1px solid $main-color !important;
            text-decoration: none;
            cursor: pointer;

            &:hover
            {
                background-color: white;
                color: $main-color;
            }
        }
    }

    & input,
    select {
        width: 100%;
        height: 3rem;
        padding: 1%;
        padding-left: 5%;
        border: none;
        outline: none;
        background-color: $grey-color;
        line-height: 3rem;
        margin-right: 5%;
        border-radius: 1vh;
    }

    & input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    & .check {
        width: auto;
    }

    & #error-code {
        color: red;
        margin-bottom: 5%;
    }
}

@media only screen and (min-width: 1000px) {
    #view {
        & #fakturaName {
            word-break: break-all;
            padding-left: 2%;
            padding-right: 2%;
        }

        & .check {
            transform: scale(2);
        }

        &-search {
            width: 50%;
            margin-top: 5%;
            margin-bottom: 5%;

            &-type {
                width: 47.5%;
                padding: 1%;
                padding-left: 5%;
                border: none;
                outline: none;
                background-color: $grey-color;
                line-height: 2rem;
                margin-right: 5%;
                border-radius: 1vh;
            }

            &-button {
                width: 47.5%;
                padding: 1%;
                border: none;
                outline: none;
                background-color: $main-color;
                color: white;
                line-height: 2rem;
                font-weight: bold;
                border-radius: 1vh;
            }

            & form {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
            }
        }

        &-archive {
            width: 80%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            //margin-bottom: 5%;

            &-top {
                background-color: $main-color;
                padding: 0;
                padding-left: 5%;
                padding-right: 5%;
                width: 100%;
                color: white;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 2%;
            }

            &-bottom {
                width: 100%;

                & .faktura {
                    margin-top: 2%;
                    background-color: $grey-color;
                    align-items: center;
                    padding: 0;
                    padding-left: 0%;
                    padding-right: 0%;
                    width: 100%;
                    color: $font-color;
                    display: flex;
                    justify-content: space-between;

                    & .make-space {
                        width: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        transition: 0.3s ease-in-out;

                        &:hover
                        {
                            color: $main-color;
                        }
                    }
                }
            }
        }



        &-file
        {
            width: 30%;

            & form,
            a {
                margin-right: 0;
            }
        }

        &-main {
            margin-top: 2%;
            width: 90%;

            &-data {
                width: 100%;

                & form {
                    & div {
                        width: 45%;
                    }

                    & #checkboxy-div {
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-around;
                    }
                }
            }

            &-products {
                flex-wrap: wrap;

                & #products-header,
                #products-summary {
                    flex-direction: row;
                    width: 100%;

                    & .wide-a {
                        width: 14.3%;
                    }

                    & a {
                        width: 7.14%;
                        padding: 1%;
                        height: auto;
                    }
                }

                & .products-list {
                    flex-direction: row;
                    margin-top: 1%;
                    margin-left: 0;

                    & .wide-a {
                        width: 14%;
                    }

                    & a,
                    input {
                        width: 7%;
                        height: auto;
                        border: none;
                    }
                }

                & #products-summary {
                    padding: 0;
                    margin-top: 1%;

                    & a {
                        justify-content: left;
                    }

                    & #super-wide-a {
                        width: 65%;
                        height: 100%;
                        text-align: left;
                    }
                }

                & #products-add {
                    margin-top: 2%;
                    margin-left: 0;
                    margin-right: 0;
                    width: 100%;
                }
            }

            &-people {
                margin-top: 5%;
                width: 100%;
                display: flex;
                justify-content: space-between;

                & div {
                    width: 47.5%;

                    & form {

                        width: 100%;
                        display: flex;
                        flex-direction: column;

                        & label {
                            margin-bottom: 4%;
                            font-weight: bold;
                        }

                        & a {
                            margin-bottom: 5%;
                            font-weight: bold;
                            font-size: 1.2rem;
                        }

                        & input {
                            margin-top: 1%;
                        }
                    }
                }
            }
        }

        &-buttons {
            width: 50%;
            display: flex;
            justify-content: space-between;
        }

        & input,
        select {
            width: 100%;
            //height: 100%;
            padding: 1%;
            padding-left: 5%;
            border: none;
            outline: none;
            background-color: $grey-color;
            line-height: 2rem;
            margin-right: 5%;
            border-radius: 1vh;
        }

        & .check {
            width: auto;
        }
    }
}
</style>