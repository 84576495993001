<template>
    <div id="view">
        Tutaj niedługo będą zasady użytkowania
    </div>
</template>
<script>
export default {
    setup()
    {
        document.title = 'Polityka dotycząca zasad użytkowania Pay24Hrs';
    }
}
</script>
<style lang="scss" scoped>
    #view
    {
        min-height: 80vh;
    }
</style>