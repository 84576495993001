<template>
    <div id="line">

    </div>
    <div id="photo">

    </div>
    <div id="main">

        <div id="login">
            <div id="login-in">

                <div id="div-a">
                    <h4>Witaj w Pay24hrs</h4>
                    <h1>Resetowanie hasła 2</h1>
                </div>
                <h4 id="grey">Nie masz konta? <router-link to="/rejestracja">zarejestruj się</router-link></h4>
                <h4>Podaj swój email, kod i nowe hasło</h4>
                <form @submit.prevent="resetPassword">
                    <input type="email" placeholder="Twój email" v-model="email" class="mail" required>
                    <input type="text" placeholder="Kod resetujący" v-model="resetCode" class="mail" required>
                    <input type="password" placeholder="Nowe hasło" v-model="newPassword" class="mail" required>
                    <router-link to="/logowanie" id="password-reset">Przypomniałeś sobie hasło?</router-link>
                    <input type="submit" class="submit" value="Zresetuj hasło" id="send"/>
                </form>
            </div>

        </div>
    </div>
</template>
<script>
import { ref, inject, onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

export default {
setup() {
    document.title = 'Resetowanie hasła';
    const endpoint = inject('g_endpoint');
    const email = ref('');
    const resetCode = ref('');
    const newPassword = ref('');
    const message = ref('');
    const router = useRouter(); // Używanie hooka useRouter() do uzyskania dostępu do instancji routera

    const resetPassword = async () => {
        try {
            const formData = new FormData();
            formData.append('email', email.value);
            formData.append('password_reset_code', resetCode.value);
            formData.append('new_password', newPassword.value);

            const response = await axios.post(`${endpoint}password_reset/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200) {
                message.value = 'Twoje hasło zostało zresetowane.';
                router.push('/logowanie'); // Przeniesienie użytkownika do strony logowania
            }
        } catch (error) {
            message.value = 'Nie udało się zresetować hasła.';
            console.error(error);
        }
    };

    onMounted(() => {
        // Pobieranie kodu resetującego z URL-a i przypisanie do resetCode.value
        if (window.location.hash) {
            const code = window.location.hash.substring(1); // Usuwamy '#' z początku
            resetCode.value = code;
        }
    });

    return { email, resetCode, newPassword, message, resetPassword };
}
}
</script>
<style lang="scss" scoped>
@import '../styles/style.scss';
    #main
    {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 20px;
        min-height: 100vh;
    }
    #line
    {
        background-color: $main-color;
        position: absolute;
        height: 50px;
        margin-top: 50px;
        z-index: -1;
        width: 100vw;
        margin-left: -10vw;
    }
    #photo
    {
        position: absolute;
        width: 80%;
        height: 50%;
        background-image: url('../assets/zdj-login.png');
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: 90vh;
        display: none;
    }
    #login
    {
        width: 100%;
        & #login-in
        {
            background-color: white;
            width: 100%;
            border-radius: 2vh;
            box-shadow: 0 0 0.8vh 0.01vh $border-color2;
            padding: 5%;

            & form
            {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                & input
                {
                    width: 100%;
                    margin: 0;
                    margin-bottom: 5%;
                }
                & .submit
                {
                    width: 100%;

                }
            }

            form
            {
                width: 100%;
                & .mail
                {
                    width: 100%;
                    padding: 1%;
                    padding-left: 5%;
                    border: none;
                    outline: none;
                    background-color: $grey-color;
                    line-height: 2rem;
                    border-radius: 1vh;
                }

                & .submit
                {
                    //height: 3rem;
                    font-size: 1rem;
                    padding: 3%;
                    padding-left: 5%;
                    padding-right: 5%;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0;

                    transition: 0.2s ease-in-out;
                    border: 1px solid $main-color;
                    border-radius: 25px;
                    text-decoration: none;
                    color: white;
                    background-color: $main-color;
                    font-weight: 200;
                    cursor: pointer;

                    &:hover
                    {
                        background-color: white;
                        color: $main-color;
                    }
                }

                #password-reset
                {
                    text-decoration: none;
                    color: $main-color;
                    width: 100%;
                    padding-left: 1%;
                    margin-top: 0;
                    margin-bottom: 5%;
                    cursor: pointer;
                }
            }
            h4
            {
                text-transform: uppercase;
            }
            h5
            {
                font-size: 1rem;
            }
            h1
            {
                text-transform: capitalize;
            }
            & #grey
            {
                color: $border-color2;
                font-weight: lighter;
                & a
                {
                    color: $main-color;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
            & #div-a
            {
                border-left: 3px solid $main-color;
                padding-left: 3%;

                & h4
                {
                    margin-bottom: 0;
                }
                & h1
                {
                    margin-top: 0;
                }
            }


        }
    }
    @media only screen and (min-width: 1000px)
    {
        #main
        {
            justify-content: right;
        }
        #photo
        {
            display: inline;
            width: 40vw;
            margin-top: 150px;
        }
        #login
        {
            width: 35%;
        }
    }
</style>