<template>
    <div id="line"></div>
    <div id="photo"></div>
    <div id="main">
        <div id="login">
            <div id="login-in">
                <div id="div-a">
                    <h4>Witaj w Pay24hrs</h4>
                    <h1>Rejestracja</h1>
                </div>
                <h4 id="grey">Masz już konto? <router-link to="/logowanie">zaloguj się</router-link></h4>
                <div id="krok-1" class="kroki" v-show="show == 1">
                    <h4>Dane organizacji</h4>
                    <form>
                        <input type="text" placeholder="Nazwa Firmy" class="mail" v-model="companyName"/>
                        <input type="text" placeholder="Kraj" class="mail" v-model="country"/>
                        <input type="text" placeholder="NIP" class="mail" v-model="NIP"/>
                        <input type="text" :placeholder="email1Placeholder" class="mail" v-model="email"/>
                        <input type="password" placeholder="Hasło" class="mail" v-model="password"/>
                        <!-- <div id="form-dots">
                            <img src="../assets/dot-active.svg"/>
                            <img src="../assets/dot.svg"/>
                            <img src="../assets/dot.svg"/>
                            <img src="../assets/dot.svg"/>
                        </div> -->
                        <input type="button" class="submit dalej" id="zakoncz" value="ZAKOŃCZ ⟶"/>
                        <a class="warning">{{ warning }}</a>
                    </form>
                </div>
                <!-- <div id="krok-2" class="kroki" v-show="show == 2">
                    <h4>Dane organizacji</h4>
                    <form>
                        <input type="text" placeholder="Rodzaj Działalności" class="mail" v-model="businessType"/>
                        <input type="text" placeholder="Numer Rejestracyjny Firmy" class="mail" v-model="NIP"/>
                        <input type="text" placeholder="Telefon" class="mail" v-model="phoneNumber"/>
                        <input type="text" :placeholder="email1Placeholder" class="mail" v-model="email"/>
                        <input type="text" placeholder="Adres URL" class="mail" v-model="urlAddress"/>
                        <div id="form-dots">
                            <img src="../assets/dot-active.svg"/>
                            <img src="../assets/dot-active.svg"/>
                            <img src="../assets/dot.svg"/>
                            <img src="../assets/dot.svg"/>
                        </div>
                        <input type="button" class="submit dalej" value="DALEJ ⟶"/>
                        <a class="wroc">Wróć</a>
                        <a class="warning">{{warning}}</a>
                    </form>
                </div>
                <div id="krok-3" class="kroki" v-show="show == 3">
                    <h4>Dane reprezentanta firmy</h4>
                    <form>
                        <input type="text" placeholder="Imię" class="mail" v-model="name"/>
                        <input type="text" placeholder="Nazwisko" class="mail" v-model="surname"/>
                        <input type="text" placeholder="Narodowość" class="mail" v-model="nationality"/>
                        <input type="text" placeholder="Miasto" class="mail" v-model="city"/>
                        <input type="date" placeholder="Data Urodzenia" class="mail" v-model="birthDate"/>
                        <div id="form-dots">
                            <img src="../assets/dot-active.svg"/>
                            <img src="../assets/dot-active.svg"/>
                            <img src="../assets/dot-active.svg"/>
                            <img src="../assets/dot.svg"/>
                        </div>
                        <input type="button" class="submit dalej" value="DALEJ ⟶"/>
                        <a class="wroc">Wróć</a>
                        <a class="warning">{{warning}}</a>
                    </form>
                </div>
                <div id="krok-4" class="kroki" v-show="show == 4">
                    <h4>Dane reprezentanta firmy</h4>
                    <form>
                        <input type="text" placeholder="Telefon" class="mail" v-model="phoneNumber2"/>
                        <input type="text" :placeholder="email2Placeholder" class="mail" v-model="email2"/>
                        <input type="password" placeholder="Hasło" class="mail" v-model="password"/>
                        <input type="text" placeholder="Pesel" class="mail" v-model="pesel"/>
                        <a id="policy">Tworząc konto zgadzasz się z naszą <router-link to="/polityka-prywatnosci">polityką prywatności</router-link> i <router-link to="/zasady-uzytkowania">zasadami użytkowania</router-link></a>
                        <div id="form-dots">
                            <img src="../assets/dot-active.svg"/>
                            <img src="../assets/dot-active.svg"/>
                            <img src="../assets/dot-active.svg"/>
                            <img src="../assets/dot-active.svg"/>
                        </div>
                        <input type="button" class="submit dalej" id="zakoncz" value="ZAKOŃCZ ⟶"/>
                        <a class="wroc">Wróć</a>
                        <a class="warning">{{warning}}</a>
                    </form>
                </div> -->
                <div id="krok-5" class="kroki" v-show="show == 2">
                    <div id="done"></div>
                    <h1>Dziękujemy za rejestrację</h1>
                    <form>
                        <input type="button" class="submit" id="przejdz" value="PRZEJDŹ DO SERWISU"/>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { onMounted, reactive, ref, toRefs, inject } from "vue";
import axios from "axios";
import router from "@/router";
export default {

    setup()
    {
        document.title = 'Rejestracja';
        const endpoint = inject("g_endpoint");

        var show = ref(1);//która część rejestracji się wyświetla
        var warning = ref("")//ostrzeżenie o niewpisanych danych

        //formularz do wysłania do api
        var form = reactive({
            companyName: "",
            country: "",
            //place: "",
            //street: "",
            //postcode: "",
            //businessType: "",
            NIP: "",
            //phoneNumber: "",
            email: "",
            //urlAddress: "",
            //name: "",
            //surname: "",
            //nationality: "",
            //city: "",
            //birthDate: "",
            //phoneNumber2: "",
            //email2: "",
            password: "",
            //pesel: "",
            email1Placeholder: "Email Organizacji",
            //email2Placeholder: "Email Reprezentanta",
        })

        //sprawdzanie poprawności maila
        const checkMail = (mail) =>
        {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(mail);
        }

        //sprawdzanie poprawności hasła
        const checkPassword = (password) =>
        {
            const emailRegex = /^.{4,}$/;
            return emailRegex.test(password);
        }

        function isValidCountryName(country)
        {
            const regex = /^[A-Za-z]+(?:[ ][A-Za-z]+)*$/;
            return regex.test(country);
        }

        function isValidNIP(nip)
        {
            const regex = /^\d{10}$/;
            return regex.test(nip);
        }


        //wysłanie formularza z rejestracją do api
        const sendForm = async () =>
        {
            if(!(checkMail(form.email) && checkPassword(form.password)))
            {
                warning.value = "Niepoprawny email lub hasło";
                return 0;
            }

            if(!(isValidCountryName(form.country) && isValidNIP(form.NIP)))
            {
                warning.value = "Niepoprawne dane";
                return 0;
            }

            let objectJSON =
            {
                email: form.email,
                password: form.password,

                //rep_first_name: form.name,
                //rep_last_name: form.surname,
                //rep_nationality: form.nationality,
                //rep_city: form.city,
                //rep_birth_date: form.birthDate,
                //rep_phone_number: form.phoneNumber2,
                //rep_pesel: form.pesel,

                org_name: form.companyName,
                //org_street_address: form.street,
                //org_zip_code: form.postcode,
                //org_city: form.place,
                //org_registration_number: form.NIP,
                org_nip: form.NIP,
                org_country: form.country,
                //org_type: form.businessType,
                org_email: form.email,
                //org_phone_number: form.phoneNumber,
                //org_url_address: form.urlAddress,
            }

            let sendData = new FormData();
            for(const [key, value] of Object.entries(objectJSON))
            {
                sendData.append(key, value)
            }

            try
            {
                const response = await axios
                .post(`${endpoint}register/`, sendData)

                if(response.status === 200)
                {
                    await router.push('/logowanie')
                }
                else
                {
                    show.value = 1;
                }
            }
            catch(error)
            {
                if(JSON.parse(error.request.response).messages[0] == "Nieprawidłowy format daty w polu rep_brith_date. Musi być YYYY-MM-DD")
                {
                    warning.value = "Nieprawidłowy format daty";
                    show.value = 3
                    console.error(JSON.parse(error.request.response).messages[0])
                }
                else
                {
                    form.email1Placeholder = "Email już istnieje"
                    warning.value = "Niepoprawny email lub hasło";

                    form.email = ""
                    show.value = 2
                    console.error(JSON.parse(error.request.response).messages[0])
                }
            }
        }


        onMounted(() =>
        {
            var dalej = document.querySelectorAll('.dalej');
            var wroc = document.querySelectorAll('.wroc');

            dalej.forEach(el => {
                el.addEventListener("click", () =>
                {
                    show.value += 1;
                });
            });

            wroc.forEach(el => {
                el.addEventListener("click", () =>
                {
                    show.value -= 1;
                });
            });

            let zakoncz = document.getElementById("zakoncz");
            zakoncz.replaceWith(zakoncz.cloneNode(true));
            document.getElementById("zakoncz").addEventListener("click", sendForm);

        })
        return {show, ...toRefs(form), warning};
    }
}
</script>
<style lang="scss" scoped>
@import '../styles/style.scss';
    #main
    {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 20px;
        min-height: 100vh;
    }
    #line
    {
        background-color: $main-color;
        position: absolute;
        height: 50px;
        margin-top: 50px;
        z-index: -1;
        width: 100vw;
        margin-left: -10vw;
    }
    #photo
    {
        position: absolute;
        width: 80vw;
        height: 50vh;
        background-image: url('../assets/zdj-login.png');
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: 90vh;
        display: none;
    }
    #login
    {
        width: 100%;
        min-height: 400px;
        & #login-in
        {
            background-color: white;
            width: 100%;
            border-radius: 2vh;
            box-shadow: 0 0 0.8vh 0.01vh $border-color2;
            padding: 5%;
            & #done
            {
                background-image: url('../assets/korzysci-2.svg');
                background-size: contain;
                background-repeat: no-repeat;
                width: 8vw;
                height: 8vw;
            }
            & #krok-5
            {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                & h1
                {
                    text-align: center;
                }
            }

            form
            {
                width: 100%;
                & .mail
                {
                    width: 100%;
                    padding: 1%;
                    padding-left: 5%;
                    border: none;
                    outline: none;
                    background-color: $grey-color;
                    line-height: 2rem;
                    border-radius: 1vh;
                }
                & .submit
                {
                    //height: 3rem;
                    font-size: 1rem;
                    padding: 1%;
                    padding-left: 5%;
                    padding-right: 5%;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0;

                    transition: 0.2s ease-in-out;
                    border: 1px solid $main-color;
                    border-radius: 25px;
                    text-decoration: none;
                    color: white;
                    background-color: $main-color;
                    font-weight: 200;
                    cursor: pointer;

                    &:hover
                    {
                        background-color: white;
                        color: $main-color;
                    }
                }
            }
            h4
            {
                text-transform: uppercase;
            }
            h5
            {
                font-size: 1rem;
            }
            h1
            {
                text-transform: capitalize;
            }

            & form
            {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                & #przejdz
                {
                    width: 100%;
                }
                & input
                {
                    width: 100%;
                    line-height: 2rem;
                    margin: 0;
                    margin-bottom: 5%;
                }
                & #policy
                {
                    line-height: 1.2rem;
                    margin-bottom: 5%;
                }
                & .submit
                {
                    width: 40%;

                }
                & div
                {
                    width: 30%;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 5%;
                }

            }
            & #grey
            {
                color: $border-color2;
                font-weight: lighter;
                & a
                {
                    color: $main-color;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
            & #div-a
            {
                border-left: 3px solid $main-color;
                padding-left: 3%;

                & h4
                {
                    margin-bottom: 0;
                }
                & h1
                {
                    margin-top: 0;
                }
            }


        }
    }
    @media only screen and (min-width: 1000px)
    {
        #main
        {
            justify-content: right;
        }
        #photo
        {
            margin-top: 150px;
            width: 40vw;
            display: block;
        }
        #login
        {
            width: 35%;
        }
    }
</style>