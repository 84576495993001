import { createRouter, createWebHistory } from 'vue-router'
import mainPage from '../views/mainPage'
import registerPage from '../views/registerPage'
import loginPage from '../views/loginPage'
import accountPage from '../views/accountPage'

import cookiesPage from '../legal/cookiesPage'
import privacyPage from '../legal/privacyPage'
import rulesPage from '../legal/rulesPage'
import safetyPage from '../legal/safetyPage'
import contactPage from '../legal/contactPage'

import zapleczePage from '../views/accountViews/zapleczePage'
import ecommercePage from '../views/accountViews/ecommercePage'
import fakturyPage from '../views/accountViews/fakturyPage'
import importPage from '../views/accountViews/importPage'
import notificationPage from '../views/accountViews/notificationPage'
import paymentsPage from '../views/accountViews/paymentsPage'
import reportPage from '../views/accountViews/reportPage'
import sendInfoPage from '../views/accountViews/sendInfoPage'
import mainView from '../views/accountViews/mainView'
import rachunekPage from '../views/accountViews/rachunekPage'

import thankYouPage from '../views/thankYouPage'
import alreadySubscribedPage from '../views/alreadySubscribedPage'
import resetPasswordPage from '../views/resetPasswordPage'
import setPasswordPage from '../views/setPasswordPage'

const routes = [
  {
    path: '/',
    name: 'mainPage',
    component: mainPage,
  },
  {
    path: '/rejestracja',
    name: 'registerPage',
    component: registerPage,
  },
  {
    path: '/logowanie',
    name: 'loginPage',
    component: loginPage,
  },
  {
    path: '/thanks',
    name: 'thankYouPage',
    component: thankYouPage,
  },
  {
    path: '/subscribed',
    name: 'alreadySubscribedPage',
    component: alreadySubscribedPage,
  },
  {
    path: '/reset-password',
    name: 'resetPasswordPage',
    component: resetPasswordPage,
  },
  {
    path: '/set-password',
    name: 'setPasswordPage',
    component: setPasswordPage,
  },
  {
    path: '/konto',
    name: 'accountPage',
    component: accountPage,
    children: [
      {
        path: "",
        name: "mainView",
        component: mainView,
      },
      {
        path: "faktury",
        name: "faktury",
        component: fakturyPage,
      },
      {
        path: "zaplecze",
        name: "zaplecze",
        component: zapleczePage,
      },
      {
        path: "ecommerce",
        name: "ecommerce",
        component: ecommercePage,
      },
      {
        path:'import/:id?',
        name:'import',
        component: importPage,
        props: true,
      },
      {
        path: "notification",
        name: "notification",
        component: notificationPage,
      },
      {
        path: "payments",
        name: "payments",
        component: paymentsPage,
      },
      {
        path: "report",
        name: "report",
        component: reportPage,
      },
      {
        path: "sendInfo",
        name: "sendInfo",
        component: sendInfoPage,
      },
      {
        path: "rachunek",
        name: "rachunek",
        component: rachunekPage,
      },
    ]
  },
  {
    path: '/polityka-cookies',
    name: 'cookiesPage',
    component: cookiesPage,
  },
  {
    path: '/polityka-prywatnosci',
    name: 'privacyPage',
    component: privacyPage,
  },
  {
    path: '/zasady-uzytkowania',
    name: 'rulesPage',
    component: rulesPage,
  },
  {
    path: '/bezpieczenstwo',
    name: 'safetyPage',
    component: safetyPage,
  },
  {
    path: '/kontakt',
    name: 'contactPage',
    component: contactPage,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition)
  {
    if (savedPosition)
    {
      return savedPosition
    }

    else
    {
      return { top: 0 }
    }
  },
})

export default router
