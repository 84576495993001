<template>
    <div id="view">
        <h2>Bezpieczeństwo</h2>
        <p>1. Wszelką komunikację związaną z pomocą techniczną prowadzimy jedynie w ramach aplikacji.</p>
        <p>2. Posiadając konto w serwisie będziesz otrzymywał powiadomienia jedynie jako zalogowany użytkownik.</p>
        <p>Nigdy nie zadzwonimy do ciebie ani nie wyślemy e-maila z prośbą o przelanie pieniędzy na inne konto.
        Nigdy nie będziemy używać portali społecznościowych do kontaktu z tobą w sprawie twojego konta lub jego danych.
        </p>
        <p>Nigdy nie poprosimy cię o pełny numeru twojej karty, datę jej ważności, kod CVV lub PIN, hasło do aplikacji, kody bezpieczeństwa, które otrzymasz SMS-em lub o jakiekolwiek inne hasło.</p>
        <h4>Wskazówki, jak uniknąć oszustwa:</h4>
        <p>Jeśli kiedykolwiek będziesz mieć wątpliwości co do wiadomości e-mail, SMS-a lub podejrzanego telefonu, rozłącz się i skontaktuj się z daną firmą za pośrednictwem oficjalnego adresu e-mail lub numeru telefonu, aby zweryfikować żądane informacje.</p>
        <p>Nigdy nie otwieraj podejrzanych linków ani załączników z niechcianych wiadomości e-mail lub SMS-ów.</p>
        <p>Jeśli korzystasz z usług tradycyjnego banku, nigdy nie klikaj ani nie podawaj danych osobowych w podejrzanych wyskakujących okienkach, które pojawiają się podczas korzystania z bankowości online.</p>
        <p>Pamiętaj jednak o zasadach bezpieczeństwa i stosuj je na co dzień.</p>
        <h4>Zastanów się zanim klikniesz albo odpowiesz</h4>
        <p>1. Nie klikaj w linki i załączniki w wiadomościach e-mail lub SMS od nieznanych nadawców.</p>
        <p>2. Uważnie czytaj SMS-y i powiadomienia, które od nas dostajesz.</p>
        <p>3. Zanim potwierdzisz transakcję, sprawdź czy numer rachunku, kwota i dane odbiorcy są prawidłowe. Inaczej - przerwij natychmiast realizację transakcji.</p>
        <h4>Chroń swoje hasła</h4>
        <p>1. Nigdy nie udostępniaj swojego loginu (NIK-u), hasła i PIN-u osobom trzecim.</p>
        <p>2. Loguj się przez stronę serwisu Pay24hrs.pl </p>
        <p>3. Korzystaj jedynie z naszego serwisu.</p>
        <h4>Chroń swój sprzęt i inforamcje</h4>
        <p>1. Stosuj silne hasła, używaj biometrii, Face ID i mobilnej autoryzacji.</p>
        <p>2. Zainstaluj program antywirusowy, a urządzenia, na których się logujesz, dodaj do zaufanych.</p>
        <p>3. Zabezpiecz sprzęty hasłem, biometrią lub PIN-em.</p>
        <h4>Zachowaj dyskrecję w sieci i publicznie</h4>
        <p>1. Gdy przesyłasz swoje dane np. e-mailem, zabezpiecz je hasłem, które wyślesz SMS-em.</p>
        <p>2. Zmień ustawienia prywatności w mediach społecznościowych, treści publikuj tylko znajomym.</p>
        <p>3. Kiedy się logujesz, tam gdzie to możliwe, włącz dodatkowe uwierzytelnianie np. SMS-em.</p>
    </div>
</template>
<script>
export default {
    setup()
    {
        document.title = 'Polityka dotycząca bezpieczeństwa Pay24Hrs';
    }
}
</script>
<style lang="scss" scoped>
    #view
    {
        min-height: 80vh;
        p, h2, h4
        {
            line-height: 1.4rem;
        }
    }
</style>