<template>
    <div>
        Tutaj będzie można zgłosić błąd - work in progress! 
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
@import 'src/styles/style.scss';
    
</style>