<template>
    <div id="view">
        <h1>Powiadomienia</h1>
        <p>W przyszłości powiadomienia będą rozwijane. Będzie możliwość usunięcia już przeczytanych, możliwość odpowiedzi na nie, a także wiele innych opcji</p>
        <div class="notification" v-for="element, index in notificationList" :key="index">
            <h2>{{ element.notificationTitle }}</h2>
            <p>{{ element.notificationText }}</p>
        </div>
    </div>
</template>
<script>
import { onMounted, ref, inject } from "vue";
import VueCookies from "vue-cookies"

import axios from "axios";
export default {
    setup()
    {
        document.title = 'Powiadomienia';
        var notificationList = ref([]);//lista powiadomień otrzymanych z api
        const endpoint = inject("g_endpoint")

        //wczytanie powiadomieńz api
        const fetchData = async () => 
        {
            try
            {
                const response = await axios.get(`${endpoint}notifications/`, {
                withCredentials: true,
                headers: {
                    'X-CSRFToken': `${VueCookies.get('TokenCSRF')}`,
                    'Authorization': `Token ${VueCookies.get('TokenDRF')}`
                }})
                response.data.forEach(element => 
                {
                    notificationList.value.push({
                        notificationTitle: element.title,
                        notificationText: element.message
                    })
                });
                notificationList.value.reverse();
            }
            catch(error)
            {
                console.error(error);
            }
        }

        onMounted(() => 
        {
            fetchData()
        })

        return {notificationList}
    }
}
</script>
<style lang="scss" scoped>
@import 'src/styles/style.scss';
    #view
    {
        width: 100%;
        min-height: 80vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        & .notification
        {
            width: 90%;
            margin-top: 2%;
            margin-bottom: 2%;
            border: 1px solid $main-color;
            padding: 1%;
            border-radius: 1vh;
        }
        & p
        {
            width: 90%;
        }
    }
</style>