<template>
    <div id="view">
        <h1>Wyślij zgłoszenie i uzyskaj szybką odpowiedź</h1>
        <form>
            <select v-model="temat">
                <option value="" disabled selected hidden>Wybierz powód zgłoszenia</option>
                <option value="integrations">Integracja z aplikacją Pay24hrs</option>
                <option value="additional bank account">Dodanie dodatkowego rachunku w Pay24hrs</option>
                <option value="technical support">Wsparcie techniczne</option>
                <option value="funds info">Informacje o środkach finansowych</option>
                <option value="application support">Obsługa aplikacji</option>
                <option value="credit card support">Obsługa kart płatniczych</option>
                <option value="opinion">Zostaw opinię</option>
            </select>
            <div id="auto-response" v-show="temat">
                <p id="response" v-html="selectedResponse"></p>
            </div>
            <label for="tresc" id="tresc-label">Treść zgłoszenia</label><textarea id="tresc" placeholder="Treść zgłoszenia" v-model="tresc"></textarea>
            <label for="email" id="email-label">Adres email</label><input type="email" id="email" placeholder="email" v-model="email"/>
        </form>
        <input type="button" id="submit" value="Wyślij"/>
        <a id="warning"></a>
    </div>
</template>
<script>
import axios from 'axios';
import { onMounted, reactive, toRefs, inject, ref, computed } from 'vue';
import VueCookies from 'vue-cookies'
export default {
    setup()
    {
        document.title = 'Pomoc Techniczna';
        var endpoint = inject("g_endpoint")

        //formularz z danymi
        var form = reactive({
            temat: "",
            tresc: "",
            email: ""
        })

        const response = ref([
            'Zanim wyślesz zgłoszenie, zobacz naszą odpowiedź<br><br>'+
            'Jeśli jesteś zainteresowany wdrożeniem i połączeniem funkcjonalności z aplikacją Pay24hrs, postępuj zgodnie z poniższymi krokami:<br>'+
            '<ol><li><strong>Rejestracja w systemie:</strong> Zarejestruj się na platformie dostawcy płatności.</li>'+
            '<li><strong>Klucze API:</strong> Uzyskaj klucze API, które są niezbędne do integracji płatności w Twojej aplikacji. Są to unikalne identyfikatory umożliwiające komunikację z systemem płatności.</li>'+
            '<li><strong>Dokumentacja API:</strong> Zapoznaj się z dokumentacją API otrzymaną od dostawcy, aby dowiedzieć się, jak zintegrować usługę z aplikacją.</li>'+
            '<li><strong>Testowanie:</strong> Przeprowadź testy integracji płatności, aby upewnić się, że wszystko funkcjonuje poprawnie.</li>'+
            '<li><strong>Bezpieczeństwo:</strong> Upewnij się, że Twoja integracja płatności jest bezpieczna i zabezpieczona przed potencjalnymi zagrożeniami.</li></ol>'+
            'Odpowiedź nie jest wystarczająca? Wyślij zgłoszenie, a skontaktujemy się z Tobą w ciągu 24 godzin.',

            'Zanim wyślesz zgłoszenie, zobacz naszą odpowiedź<br><br>'+
            'Aby dodać do swojego konta użytkownika dodatkowy rachunek:<br>'+
            '<ol><li><strong>Logowanie:</strong> Wejdź na stronę Pay24hrs i zaloguj się na swoje konto.</li>'+
            '<li><strong>Dodatkowy Rachunek:</strong> W panelu użytkownika wybierz zakładkę „Dodatkowy Rachunek” i wprowadź dane nowego rachunku bankowego (numer rachunku, nazwę banku, typ konta, itp.).</li>'+
            '<li><strong>Zapisz:</strong> Zatwierdź wprowadzone informacje, klikając przycisk zapisz.</li>'+
            '<li><strong>Przełączanie między rachunkami:</strong> Możesz łatwo przełączać się między rachunkami, korzystając z funkcji „Przełącz Rachunek”.</li>'+
            '<li><strong>Testowanie:</strong> Spróbuj przelać niewielką kwotę lub dokonać wypłaty, aby upewnić się, że rachunek został prawidłowo dodany.</li>'+
            '<li><strong>Ustawiena:</strong> Po dodaniu nowego rachunku, możesz dostosować ustawienia, takie jak limity transakcyjne.</li></ol>'+
            'Uwaga: Dodatkowe rachunki muszą być rachunkami firmowymi.<br><br>'+
            'Odpowiedź nie jest wystarczająca? Wyślij zgłoszenie, a skontaktujemy się z Tobą w ciągu 24 godzin.',

            'W przypadku problemów technicznych z aplikacją Pay24hrs, wyślij zgłoszenie do naszego wsparcia technicznego. Postaramy się rozwiązać problem w ciągu 24 godzin.',

            'Pay24hrs nie inwestuje ani nie pożycza środków finansowych użytkowników. Nie dokonujemy także przelewów bankowych w imieniu użytkowników. Wszystkie operacje finansowe są wykonywane samodzielnie przez użytkowników. Klienci biznesowi są weryfikowani przez banki i mogą korzystać z naszej aplikacji za pomocą firmowych rachunków bankowych.',

            'Zanim wyślesz zgłoszenie, zobacz naszą odpowiedź<br><br>'+
            'Jeśli masz pytania dotyczące korzystania z aplikacji Pay24hrs, oto kilka wskazówek:<br>'+
            '<ol><li><strong>Logowanie i rejestracja:</strong> Zarejestruj się i zaloguj do aplikacji.</li>'+
            '<li><strong>Pliki księgowe:</strong> W panelu głównym wczytaj plik księgowy, aby stworzyć listę płatności.</li>'+
            '<li><strong>Regulamin i usuwanie konta:</strong> W zakładce „Ustawienia Konta” znajdziesz regulamin aplikacji oraz opcję usunięcia konta użytkownika.</li>'+
            '<li><strong>Przełączanie rachunków:</strong> Wybierz rachunek firmy, którego chcesz użyć.</li>'+
            '<li><strong>Archiwum faktur:</strong> Przechowuj faktury i edytuj je ponownie w edytorze.</li>'+
            '<li><strong>Edytor faktur:</strong> Wczytuj informacje do faktur za pomocą pliku księgowego, a następnie generuj fakturę do pliku PDF.</li>'+
            '<li><strong>Płatności zbliżeniowe:</strong> Podpięcie karty płatniczej do Apple Pay lub Google Pay.</li>'+
            '<li><strong>E-Commerce:</strong> Integracja systemu płatniczego z platformami e-commerce.</li>'+
            '<li><strong>Pomoc techniczna:</strong> Wysyłanie zapytań do administratora.</li>'+
            '<li><strong>Centrum powiadomień:</strong> Otrzymywanie informacji o aktualizacjach i nowościach.</li></ol>'+
            'Odpowiedź nie jest wystarczająca? Wyślij zgłoszenie, a skontaktujemy się z Tobą w ciągu 24 godzin.',

            'Pay24hrs nie wydaje ani nie obsługuje firmowych kart płatniczych. Nie ustawiamy także limitów płatności. W przypadku problemów z kartą, prosimy o kontakt z bankiem lub operatorem karty.',

            'Twoja opinia jest dla nas ważna. Podziel się swoimi doświadczeniami i sugestiami dotyczącymi aplikacji Pay24hrs.'
        ]);

        const topics = {
        'integrations': 0,
        'additional bank account': 1,
        'technical support': 2,
        'funds info': 3,
        'application support': 4,
        'credit card support': 5,
        'opinion': 6
        };

        const selectedResponse = computed(() => {
        const index = topics[form.temat]; // Uzyskanie indeksu na podstawie wybranego tematu
        return response.value[index] ? response.value[index] : 'Wybierz temat, aby wyświetlić odpowiedź.'; // Zwróć odpowiedź lub domyślny komunikat
        });


        //pobranie emaila
        const getEmail = async () =>
        {
            try
            {
                let response = await axios
                .get(`${endpoint}users/${VueCookies.get("UserId")}`,
                {
                    withCredentials: true, headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': `${VueCookies.get('TokenCSRF')}`,
                    'Authorization': `Token ${VueCookies.get('TokenDRF')}`
                }});
                form.email = response.data.email
            }
            catch(error)
            {
                console.error(error);
            }
        }

        //wysłanie formualrza do api
        const sendForm = async () =>
        {
            const now = new Date();

            const year = now.getFullYear(); // Rok
            const month = String(now.getMonth() + 1).padStart(2, '0'); // Miesiąc (zaczynając od 0, dlatego +1)
            const day = String(now.getDate()).padStart(2, '0'); // Dzień miesiąca
            const hours = String(now.getHours()).padStart(2, '0'); // Godzina
            const minutes = String(now.getMinutes()).padStart(2, '0'); // Minuta
            const seconds = String(now.getSeconds()).padStart(2, '0'); // Sekunda

            const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

            let objectJSON =
            {
                subject: form.temat,
                message: form.tresc,
                email: form.email,
                name: form.email,
                send_time: formattedDateTime,
                agreed_to_process_data: true,
                type: form.temat,
            }

            let sendData = JSON.stringify(objectJSON);

            try
            {
                await axios
                .post(`${endpoint}send_contact_message/`, sendData, {
                    withCredentials: true, headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': `${VueCookies.get('TokenCSRF')}`,
                    'Authorization': `Token ${VueCookies.get('TokenDRF')}`
                }});
                document.getElementById("warning").innerHTML = "Zgłoszenie zostało wysłane"
                document.getElementById("warning").style = "color: green"
                form.temat = ""
                form.tresc = ""
            }
            catch(error)
            {
                document.getElementById("warning").innerHTML = "Zgłoszenie zostało wysłane"
                document.getElementById("warning").style = "color: green"
                form.temat = ""
                form.tresc = ""
                form.email = ""
            }
        }

        onMounted(() =>
        {
            getEmail()
            document.getElementById("submit").addEventListener("click", sendForm);
        })

        return {...toRefs(form), selectedResponse}
    }
}
</script>
<style lang="scss" scoped>
@import 'src/styles/style.scss';
    #view
    {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & h1
        {
            text-align: center;
        }
        & form
        {
            width: 90%;
            display: flex;
            flex-direction: column;
            margin-top: 5%;
            & label
            {
                margin-top: 3%;
                margin-bottom: 1%;

            }
            #auto-response #response
            {
                margin-bottom: 0 !important;
            }
            & textarea
            {
                resize: vertical;
                min-height: 10rem;
                height: auto;
                padding-top: 0.9rem;
            }
        }
    }
    input, select, textarea
    {
        width: 100%;
        height: 3rem;
        padding-left: 3%;
        font-size: 1rem;
        border: none;
        outline: none;
        background-color: $grey-color;
        color: $font-color;
        line-height: 1.3rem;
        border-radius: 1vh;
    }
    #submit
    {
        margin-top: 5%;
        width: 20%;
        padding: 1%;
        border: none;
        outline: none;
        background-color: $main-color;
        color: white;
        line-height: 2rem;
        font-weight: bold;
        border-radius: 1vh;

        display: flex;
        justify-content: center;
        align-items: center;

        transition: 0.2s ease-in-out;
        border: 1px solid $main-color !important;
        text-decoration: none;
        cursor: pointer;

        &:hover
        {
            background-color: white;
            color: $main-color;
        }
    }
    #warning
    {
        margin-top: 2%;
        margin-bottom: 2%;
    }
</style>