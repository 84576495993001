<template>
    <div id="container-main">
        <div id="main-1" :class="{'landing-page-active': $route.path === '/'}">
            <RouterView/>
        </div>
    </div>
</template>
<script>
export default {
    name: 'App',
    components:
    {
    }

}
</script>
<style lang="scss" scoped>
    #container-main
    {
        //user-select: none;
        display: flex;
        justify-content: center;

        #main-1
        {
            padding-top: 100px;
            width: 80vw;
        }
        .landing-page-active
        {
            max-width: 1500px;
        }
    }
</style>