<template>
    <div id="view">
        <div id="view-future-info">
            <h2>Opłać wszystkie faktury jednym kliknięciem</h2>
            <p>W Panelu Użytkownika masz możliwość wczytania plików księgowych. Dane do przelewu zostaną z nich pobrane automatycznie, a lista płatności sama się utworzy. Teraz wystarczy tylko wybrać, które przelewy chcemy zlecić.</p>
            <label for="import-file" id="view-file">
                <form>
                    <label for="import-file">
                        <input type="file" id="import-file" accept=".xml" multiple @change="handleFiles"/>
                        <img src="@/assets/import-2.svg" />
                        <div>Zaimportuj Plik</div>
                    </label>
                </form>
            </label>
            <div id="view-future-info-table">
                <div id="view-future-info-table-header">
                    <a>Kategoria</a>
                    <a>Nazwa przelewu</a>
                    <a>Waluta</a>
                    <a>Kwota</a>
                    <a>Symbol przelewu</a>
                    <a>Data przelewu</a>
                </div>
                <div class="table-inside" v-for="element in clientList" :key="element.numer">
                    <a>{{ element.numer }}</a>
                    <a>{{ element.nazwa }}</a>
                    <a>{{ element.waluta }}</a>
                    <a>{{ element.kwota }}</a>
                    <a>{{ element.symbol }}</a>
                    <a>{{ element.data }}</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { inject, ref } from 'vue';

export default {
    setup() {
        document.title = 'Panel Użytkownika';
        const endpoint = inject("g_endpoint");
        const clientList = ref([]); // Lista klientów (faktur) do wyświetlenia w tabeli

        // Funkcja obsługująca wczytywanie plików
        const handleFiles = async (event) => {
        const files = Array.from(event.target.files); // Przekształcenie FileList na tablicę
        const fileContents = await Promise.all(files.map(file => { // Asynchroniczne wczytanie zawartości wszystkich plików
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = evt => resolve(evt.target.result); // Zwrócenie zawartości pliku po wczytaniu
                reader.onerror = err => reject(err); // Obsługa błędów wczytywania pliku
                reader.readAsText(file); // Wczytanie pliku jako tekst
            });
        }));

        // Dla każdego wczytanego pliku wysyłanie zawartości do API i aktualizacja listy klientów
        fileContents.forEach(async (content) => {
            try {
            let response = await axios.post(`${endpoint}get_factures_data/`, content, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/xml' }
            });
            const newElement = response.data[0]; // Zakładamy, że API zwraca tylko jeden element
            updateClientList(newElement); // Aktualizacja listy klientów o nowy element
            } catch (error) {
            console.error(error); // Logowanie błędu, jeśli wystąpi
            }
        });
        };

        // Funkcja aktualizująca listę klientów o nowy element
        const updateClientList = (newElement) => {
            let bruttoTotal = 0; // Obliczenie sumy brutto wszystkich produktów w fakturze
            if(newElement.products && Object.keys(newElement.products).length > 0){
                for (const key in newElement.products) {
                const product = newElement.products[key];
                bruttoTotal += Number(product.brutto);
                }
            }

            // Dodanie nowego elementu do listy klientów
            clientList.value.push({
                nazwa: newElement.number,
                numer: clientList.value.length + 1,
                waluta: newElement.currency,
                kwota: bruttoTotal.toFixed(2),
                symbol: newElement.series,
                data: newElement.issue_date
            });
        };

        return { clientList, handleFiles };
    }
}
</script>


<style lang="scss" scoped>
@import 'src/styles/style.scss';
    #view
    {
        display: flex;
        justify-content: center;
        width: 100%;

        &-file
        {
            height: 4rem;
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-top: 2%;
            margin-bottom: 2%;

            cursor: pointer;
            border: 1px solid $main-color;
            padding: 0.5rem;
            border-radius: 1vh;

            & a
            {
                background-color: $grey-color;
                border-radius: 1vh;
                width: 100%;
                margin-right: 5%;
            }

            & form
            {
                width: 3rem;
            }

            & form, a
            {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                white-space: nowrap;

                & input {
                    display: none;
                }

                & label {
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    div
                    {
                        padding: 0.5rem;
                    }
                }

                & img {
                    width: auto;
                    height: 100%;
                    cursor: pointer;
                }
            }
        }
    }

    #view-future-info
    {
        width: 90%;
        & p
        {
            line-height: 1.5rem;
        }
        &-table
        {
            display: flex;
            flex-direction: row;
            overflow-x: auto;
            margin-bottom: 1%;
            & div
            {
                width: auto;
                display: flex;
                flex-direction: column;
                padding-left: 2%;
                padding-right: 2%;
                margin-right: 3%;
                & a
                {
                    width: 100%;
                    height: 3rem;
                    justify-content: flex-start;
                    display: flex;
                    align-items: center;
                }
            }
        }
        & #view-future-info-table-header
        {
            background-color: $main-color;
            color: white;
            border-radius: 1vh;
        }
        & .table-inside
        {
            background-color: $grey-color;
            border-radius: 1vh;
        }
    }

@media only screen and (min-width: 1000px)
{
    #view
    {
        &-file
        {
            flex-direction: row;
            width: 25%;
            justify-content: flex-start;
        }
    }

    #view-future-info
    {
        &-table
        {
            flex-direction: column;
            overflow-x: visible;

            margin-bottom: 2%;
            & div
            {
                width: 100%;
                flex-direction: row;
                padding: 0;
                & a
                {
                    justify-content: center;
                }
            }
        }
        & .table-inside
        {
            margin-top: 1%;
        }
    }
}
</style>