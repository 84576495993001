<template>
    <div id="high">
        Tutaj kiedyś zostanie dodana opcja dodawania drugiego rachunku - work in progress!
    </div>
</template>
<script>
export default {
    setup()
    {
        document.title = 'Dodaj Rachunek';
    }
}
</script>
<style lang="scss" scoped>
@import 'src/styles/style.scss';
    #high
    {
        min-height: 10vh;
        padding: 2%;
    }
</style>