<template>
    <div id="settings">
        <div class="setting">
            <h1>Zmiana hasła</h1>
            <form id="settings-password-form" @submit.prevent="changePassword">
                <input type="password" v-model="newPassword" placeholder="Podaj nowe hasło"/>
                <input type="password" v-model="confirmPassword" placeholder="Potwierdź nowe hasło"/>
                <button id="button" type="submit">Zmień hasło</button>
            </form>
            <p v-if="errorMessage">{{ errorMessage }}</p>
        </div>
        <div class="setting">
            <h1>Usunięcie konta</h1>
            <form id="settings-remove-form" @submit.prevent="deleteAccount">
                <button id="button" type="submit" >Usuń konto</button>
            </form>
        </div>
    </div>
</template>
<script>
import { ref, inject } from 'vue';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import { useRouter } from 'vue-router';

export default {
    setup() {

        document.title = 'Konto';
        const endpoint = inject('g_endpoint');
        const newPassword = ref('');
        const confirmPassword = ref('');
        const errorMessage = ref('');
        const router = useRouter();

        const changePassword = async () => {
            if (newPassword.value !== confirmPassword.value) {
                errorMessage.value = "Podane hasła nie są identyczne.";
                return;
            }

            const formData = new FormData();
            formData.append('new_password', newPassword.value);

            try {
                const response = await axios
                .post(`${endpoint}users/change_password/`, formData, {
                    withCredentials: true, headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-CSRFToken': `${VueCookies.get('TokenCSRF')}`,
                    'Authorization': `Token ${VueCookies.get('TokenDRF')}`
                }});

                // Obsługa sukcesu
                if (response.status === 200) {
                    errorMessage.value = "Hasło zostało zmienione.";
                }
            } catch (error) {
                errorMessage.value = `Błąd serwera: ${error.response.status}`;
                console.error(error)
            }
        };

        const deleteAccount = async () => {
            if (!confirm("Czy na pewno chcesz usunąć swoje konto? Ta akcja jest nieodwracalna.")) {
                return;
            }

            // Tworzenie instancji FormData
            //const formData = new FormData();
            //const userId = VueCookies.get('UserId'); // Pobranie userId z ciasteczek
            //formData.append('user_id', userId); // Dodanie userId do żądania

            try {
                await axios.delete(`${endpoint}users/delete_account/`, {
                    withCredentials: true,
                    headers: {
                        'X-CSRFToken': VueCookies.get('TokenCSRF'),
                        'Authorization': `Token ${VueCookies.get('TokenDRF')}`
                    }
                });
                // Obsługa sukcesu
                alert("Twoje konto zostało usunięte.");
                // Przekierowanie do strony głównej lub logowania
                try
                {
                    await axios.get(`${endpoint}logout/`)
                    VueCookies.remove("tokenCSRF")
                    VueCookies.remove("tokenDRF")
                    VueCookies.remove("userId")
                    const cookies = document.cookie.split(";")
                    cookies.forEach(cookie =>
                    {
                        const eqPos = cookie.indexOf("=");
                        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`
                    });
                    await router.push("/");
                }
                catch(error)
                {
                    console.error(error)
                }
            } catch (error) {
                console.error("Błąd podczas usuwania konta:", error);
                alert("Wystąpił błąd podczas usuwania konta.");
            }
        };

        return { newPassword, confirmPassword, changePassword, deleteAccount, errorMessage };
    }
}
</script>
<style lang="scss" scoped>
@import 'src/styles/style.scss';
    #settings
    {
        padding-left: 5%;
        padding-right: 5%;

        .setting
        {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 5%;

            form
            {
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;

                input
                {
                    width: 100%;
                    padding: 1%;
                    padding-left: 5%;
                    border: none;
                    outline: none;
                    background-color: $grey-color;
                    line-height: 2rem;
                    border-radius: 1vh;
                    margin-bottom: 2.5%;
                }

                #button
                {
                    //height: 3rem;
                    width: 60%;
                    font-size: 1.5rem;
                    padding: 1%;
                    padding-left: 5%;
                    padding-right: 5%;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0;

                    transition: 0.2s ease-in-out;
                    border: 1px solid $main-color;
                    border-radius: 25px;
                    text-decoration: none;
                    color: white;
                    background-color: $main-color;
                    font-weight: 200;
                    cursor: pointer;

                    &:hover
                    {
                        background-color: white;
                        color: $main-color;
                    }
                }
            }
        }

    }
</style>